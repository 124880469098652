@import 'variables';
@import 'icons';
@import 'mixins';
@import 'interstitial';
@import '~core/styles/responsive-utilities';
@import '~core/styles/grid';
@import '~core/styles/mixins';
@import '~core/styles/defaults';

/* Fonts */
@import 'fonts';
@import '~core/fonts/fontello/css/fontello';
@import '~org/fonts/ua-categories/css/ua_categories';

html {
  width: 100%;

  &.mn_isIphoneSafari {
    overflow-y: initial;
  }

  &.mn_open {
    @media #{$less-than-medium-screen} {
      overflow-y: hidden;
    }
  }
}

body {
  // Global overrides
  $brandLinkColor: #2172ba;
  $brandLinkColorHover: $brandLinkColor;
}

label { font-weight: 500; }

.mn_checkbox {
  label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 2rem;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: top;
      margin: 0 8px 0 0;
      background: #fff;
      height: 20px;
      width: 20px;
      border: 2px solid #0c2340;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }

  input[type='checkbox'] {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    &:checked + label:before {
      border-color: transparent;
      background: #000 url('../assets/images/checkbox.svg') no-repeat -2px -2px;
    }
  }

  &:hover,
  &:focus {
    opacity: .8;
  }
}

.mn_button.mn_secondaryButton {
  background: #fff;
  color: #6244bb;

  &:hover {
    background: #f2f2f2;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .5);
  }
}

.mn_check {
  max-height: 80px;
  max-width: 80px;
}

.mn_arrowLink {
  &:after {
    display: inline-block;
    font-size: 1.1rem;
    font-weight: bold;
    font-family: 'UnitedIcons'; // stylelint-disable-line font-family-no-missing-generic-family-keyword
    content: '\e90a';
    margin-left: 4px;
  }
}

.mn_noScroll {
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.mn_sectionHighlited {
  position: relative;
  padding: 4rem 0;

  &:empty {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    right: -100%;
    bottom: 0;
    z-index: -1;
    background: #ededf6;
    box-shadow: inset 0 5px 10px rgba(0, 0, 0, .05);
  }

  .mn_carousel:after {
    display: none;
  }
}

// react css transitions
.mn_slideup-appear {
  transition: transform 2000ms ease;
  transform: translateY(100%);

  &-active {
    transform: translateY(0%);
  }
}

// react css transitions
.mn_slidedown-appear {
  transition: transform 2000ms ease;
  transform: translateY(-10%);

  &-active {
    transform: translateY(0%);
  }
}

.mn_slideright-appear {
  transition: transform 2000ms ease;
  transform: translateX(-100%);

  &-active {
    transform: translateX(0%);
  }
}

.mn_pageWrapper {
  //  Sticky footer implementation
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  .mn_pageBody {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    .mn_open & {
      @media #{$less-than-medium-screen} {
        display: none;
      }
    }
  }

  .mn_pageBodyContent {
    flex: 1 0 auto;
  }
}

.mn_emailOptInSubscribeModal .mn_onboardingOptin .mn_optInImage {
  @include radial-shadow(null, -30px, 10px, .2);
}

// Onboarding
.mn_onboarding {
  .mn_welcomeStepWrap {
    .mn_onboardingWelcome {
      .mn_brandLogo {
        @include radial-shadow(null, -40px, 10px, .2);
      }
    }
  }

  .mn_emailStepWrap {
    .mn_onboardingOptin {
      .mn_optInImage {
        @include radial-shadow(null, -25px, 10px, .2);
      }
    }
  }

  .mn_buttonStepWrap {
    .mn_onboardingButton {
      h2 {
        @include radial-shadow(null, -30px, 10px, .2);
      }
    }
  }
}

// VHP
html.mn_VHP {
  .mn_headerMainBar {
    &:after {
      display: none;
    }
  }

  .mn_testimonialText {
    span:before,
    span:after {
      content: '"';
    }
  }

  .mn_shopWifiHiw {
    @media #{$medium-screen} {
      .mn_hiwSteps {
        .mn_hiwStep {
          margin-bottom: 6rem;
        }

        h3 {
          margin-top: 2.8rem;
          margin-bottom: 1.1rem;
        }
      }

      .mn_sectHead {
        text-align: left;
        margin-bottom: 6rem;
      }
    }

    @media #{$large-screen} {
      .mn_hiwSteps {
        margin-bottom: 3.7rem;
      }

      .mn_sectCont {
        padding-bottom: 0;
      }
    }
  }

  .mn_buttonBenefits {
    background-color: #ededf6;
  }

  @media #{$less-than-medium-screen} {
    .mn_fullScreen {
      position: fixed;
      height: 100%;
    }
  }

  @media #{$medium-screen} {
    .mn_featuredOffersLogo .mn_merchantTile {
      .mn_extraRewardsPill {
        top: 52%;
      }
    }

    .mn_headerInner {
      background: linear-gradient(180deg, #000 0, rgba(40, 41, 62, .1) 20%, rgba(125, 129, 195, 0) 91.92%);
      box-shadow: inset 5px 120px 48px -55px rgba(0, 0, 0, .8);
    }

    &.mn_open #mn_h .mn_pageHeader.mn_isAnonymous:not(.mn_sticky) {
      .mn_headerMainBar {
        background: none;
      }
    }
  }

  .mn_merchantLogos .mn_list > * {
    @media (max-width: 400px) {
      flex-basis: calc((100% / 3) - 1rem + (1rem / 3));
    }
  }
}

#mn_bc {
  background: #0c2340;

  .mn_pageBodyContent {
    padding: 2rem 1.5rem 0 1.5rem;

    .mn_sectionTitle {
      span,
      h2 {
        color: #fff;
      }
    }
  }
}

#mn_sp,
#mn_fs {
  .mn_contentSectionTop {
    background: #0c2340;
  }

  .mn_popularOffers {
    .mn_list {
      justify-content: center;
    }

    .mn_offerWrap {
      flex-grow: 0;
      width: 30rem;
    }
  }

  .mn_featuredOffers502x272 {
    margin: 0 0 3rem 0;

    .mn_sectionTitle {
      span {
        color: #fff;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: 1.05px;
        line-height: 2.4rem;
      }

      h2 {
        color: #fff;
        margin-top: 0;
      }
    }
  }

  .mn_infoBar {
    margin: 0;
    padding: 3rem 0;
  }

  .mn_offerTable {
    margin: 3rem 0 0 0;
  }
}

#mn_h {
  .mn_hiwVideoModal {
    min-height: 36rem;
  }

  .mn_topContentSection {
    .mn_letterboxTOF {
      position: relative;
      padding-bottom: 4rem;

      &:after {
        content: '';
        display: block;
        height: 2rem;
        bottom: .5rem;
        position: absolute;
        left: 0;
        right: 0;
        background: radial-gradient(ellipse at 50% 0, rgba(0, 0, 0, .25), rgba(12, 34, 64, .25) 70%, rgba(0, 0, 0, 0) 65%);
      }
    }
  }

  .mn_letterboxCarouselControls .mn_playPauseControls {
    position: relative;
    top: -3px;

    button:before {
      content: '';
      display: block;
      background: url('../assets/images/pause.svg') no-repeat 50% 50%;
      height: 20px;
      width: 20px;
    }

    button.fa-play:before {
      background: url('../assets/images/play.svg') no-repeat 80% 50%;
    }
  }

  .mn_backToTopButtonSticky.mn_isAnonymous .mn_backToTopButton.mn_isSticky {
    bottom: 15rem;
  }
}

#mn_dynamicRewards .mn_pageBodyContent {
  background: #fff;
  position: relative;
}

#mn_nr {
  background: #fff;

  .mn_goBackLink {
    i {
      &:before {
        content: '\e90f';
        font-family: 'UnitedIcons', serif;
        font-size: 1rem;
        display: inline-block;
        padding-right: .5rem;
      }
    }
  }
}

#mn_e {
  .mn_errorMessage {
    max-width: 850px;
    margin: 0 auto;

    .mn_sectionTitle {
      position: relative;
      z-index: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 25rem;
      padding: 0 0 2rem;
      margin: 0 0 6rem;
      border-bottom: rgba(182, 184, 220, .5) solid 1px;
      color: #fff;

      .mn_errorCode {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-54%);
        left: 0;
        right: 0;
        font-family: $brandFontFamilyLight;
        font-size: 20rem;
        font-weight: normal;
        line-height: 1;
        text-align: center;
        z-index: -1;
        color: rgba(182, 184, 220, .25);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }

    @media #{$medium-screen} {
      padding: 0 2rem 8rem;

      .mn_sectionTitle {
        height: 45rem;

        h2 {
          font-size: 4rem;
        }

        .mn_errorCode {
          font-size: 40rem;
          padding: 2rem 0 0;
        }
      }

      .mn_errorSubheading {
        font-size: 2.4rem;
        padding: 0 11rem;
      }
    }
  }

  .mn_brandFooter {
    @include extraRewardsPillAlignedBackground(null, 100%);

    &:before {
      left: 0;
      right: 0;
      background: #1c202e url('../assets/images/error-bg.jpg') no-repeat 50% 100%;
      background-size: cover;
      height: 700px;
    }
  }
}

// Button landing
.mn_buttonLandingMobile {
  .mn_buttonLandingMobileWrap {
    border: $brandNeutralMediumColor solid 1px;
    border-radius: 4px;
    border-left: 8px solid #d50032;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
    padding: 1.3rem 5rem 2.5rem 6.4rem;

    .mn_alertIcon:before {
      content: '';
      display: block;
      background: url('../assets/images/alert.svg') 0 0/24px 21px no-repeat;
      width: 24px;
      height: 21px;
    }
  }

  .mn_buttonLandingMobileImage {
    background: linear-gradient(to top, rgba(182, 184, 220, .25) 0%, $brandWhite 100%);
    padding: 1rem 2rem 2.7rem;
  }

  .mn_buttonLandingMobileFooter .mn_button {
    width: 100%;
    max-width: 38rem;
    margin: auto;
  }
}

/* Add to favorite pop up */
.mn_firstFavoriteModal {
  h2 {
    position: relative;
    margin: 16rem 0 .5rem;

    &:before {
      content: '';
      background: url('../assets/images/firstFavorite.jpg') no-repeat 50%;
      background-size: contain;
      display: inline-block;
      width: 100%;
      height: 12rem;
      position: absolute;
      left: 0;
      right: 0;
      top: -16.5rem;
    }

    &:after {
      content: 'success';
      display: block;
      position: absolute;
      text-transform: uppercase;
      letter-spacing: 1.05px;
      font-weight: bold;
      font-size: 1.4rem;
      top: -3rem;
      left: 0;
      right: 0;
    }
  }
}

// Acquisition page
#mn_acq {
  .mn_headerTopNav:after {
    content: none;
  }
}

#mn_shopwifi {
  .mn_pageHeader.mn_isAnonymous {
    .mn_searchBar {
      margin-left: auto;
    }

    .mn_mainNavigationTOF {
      display: none;
    }

    .mn_navigationWrap {
      .mn_helpSection,
      .mn_instoreSection,
      .mn_headerExtraRewardsSection,
      .mn_favoritesSection {
        display: none;
      }
    }

    @media #{$xlarge-screen} {
      .mn_userSection {
        margin-right: 17rem;
      }
    }
  }
}

.mn_navPopularStoresSection .mn_viewAllPopularStoresLink,
.mn_navSeasonalFlyoutSection .mn_link,
.mn_navCategoryFeaturedSection .mn_seeAllLink,
.mn_headerExtraRewards .mn_linkBottom {
  @include mn_linkWithArrow();
}

.mn_instoreCuratedOffersSection .mn_viewAllLinkMobileWrap .mn_viewAllLink {
  @include mn_linkWithArrow(.8rem);
}

.mn_navBanner .mn_navBannerLinkWrap a {
  @include mn_linkWithArrow(.8rem);
}

#mn_favorites {
  .mn_favoritesLandingHiw {
    .mn_closeHiwIconWrap {
      top: -7rem;

      &:before,
      &:after {
        position: absolute;
        height: 1px;
        content: '';
        top: 4rem;
      }

      &:before {
        background:
          linear-gradient(
            90deg,
            rgba(212, 212, 208, 0) 0,
            rgba(212, 212, 208, .5) 17.53%,
            rgba(212, 212, 208, .5) 52.37%,
            rgba(212, 212, 208, .5) 80.86%,
            rgba(212, 212, 208, .5) 99.97%
          );
        left: 0;
        right: 8.4rem;
      }

      &:after {
        background:
          linear-gradient(
            90deg,
            rgba(212, 212, 208, .5) 0,
            rgba(212, 212, 208, .5) 17.53%,
            rgba(212, 212, 208, .5) 52.37%,
            rgba(212, 212, 208, .5) 80.86%,
            rgba(212, 212, 208, 0) 99.97%
          );
        right: 0;
        width: 3rem;
      }
    }
  }
}

/* My Account Page */
#mn_ai {
  background: #fff;

  .mn_bonusTracker {
    .mn_contentWrap {
      width: $large;
    }
  }
}

#mn_button-safari-tutorial {
  .mn_adBlockWarning {
    display: none;
  }

  .mn_pageHeader {
    display: none;
  }

  .mn_pageHeaderOffset {
    display: none;
  }

  .mn_brandFooter {
    display: none;
  }
}

.mn_pageHeader {
  .mn_contentWrap {
    width: $xxlarge;
  }
}

.mn_MHP {
  .mn_backToTopButtonSticky {
    display: none;
  }

  @media #{$medium-screen} {
    .mn_backToTopButtonSticky {
      display: block;
    }
  }
}

#mn_dynamicRewards,
#mn_faq,
#mn_hiw,
#mn_privacy,
#mn_terms,
#mn_eula,
#mn_shopwifi {
  .mn_pageBodyContent .mn_contentWrap {
    width: 1084px;
  }
}

#mn_favorites .mn_pageBodyContent .mn_favoritesManager .mn_contentWrap {
  width: 1084px;
}
