@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_accountNavigation {
  display: flex;
  justify-content: flex-end;
  color: #fff;
  width: 32rem;

  /* Account Navigation elements */
  .mn_navigationWrap {
    display: flex;
    align-items: center;
    margin: 0 -1rem 0 0;
    padding: 0;
    list-style: none;

    & > li:not(.mn_userSection) {
      margin: 0;
      padding: 0 .5rem;
    }

    .mn_navigationItem {
      display: block;
      padding: 0 .5rem 1.3rem;
      margin-bottom: -1.3rem;
      text-decoration: none;
      color: #fff;
      text-align: center;
      background: none;
      border: none;

      & > i {
        display: block;
        width: 20px;
        height: 22px;
        margin-bottom: -2px;
        line-height: 20px;
        font-size: 1.8rem;
      }
    }

    a.mn_navigationItem:hover {
      outline: none;
      box-shadow: 0 2px 0 -1px #e9e9e9;
    }

    .mn_searchTrigger {
      cursor: pointer;
    }
  }

  /* Dropdown */
  .mn_navigationDropdownTrigger {
    position: relative;
    margin: 0 -.5rem -2.5rem;
    padding: 0 .5rem 2.5rem;

    .mn_navigationItem {
      position: relative;
      z-index: 2;
    }

    .mn_navigationDropdown {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(-25%, -28px, 0);
      transition: all .3s ease-in-out;
      position: absolute;
      top: 100%;
      right: -14rem;
      width: 27.5rem;
      z-index: 2;
      pointer-events: none;
      text-align: left;
      background: #fff;
      color: #000;
      padding: 5px;
      border-radius: 4px;
      border: #ccc 1px solid;
      box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 5px #e6e6e6, .5px .5px 2px 0 rgba(0, 0, 0, .2);

      ul {
        margin: 1rem 0;
        padding: 0;
        list-style: none;

        li {
          margin: 0;
          padding: 0;
          font-size: 1.4rem;
          line-height: 2.4rem;
        }

        a {
          padding: .3rem 1.7rem;
          display: block;
        }
      }
    }

    &.mn_focused {
      .mn_navigationDropdown {
        opacity: 1;
        transform: translate3d(-25%, 0, 0);
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  /* User section */
  .mn_userSection {
    height: 6rem;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 1.5rem;
    margin: 0 1rem 0 0;

    .mn_userInfo {
      margin-right: .5rem;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1.03px;
      line-height: 2rem;
      text-align: right;

      .mn_userNameWrap {
        display: block;
        white-space: nowrap;

        .mn_userName {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 17rem;
          vertical-align: bottom;
        }
      }
    }

    .mn_navigationDropdown {
      .mn_highlitedSection {
        padding: 1.2rem 1.7rem;
        background: #f7f7f7;
        color: #000;

        .mn_userId {
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: .5rem;
        }

        .mn_lifetimeEarning {
          font-size: 1.2rem;
          margin: 0 0 .5rem;
        }
      }
    }

    .mn_button {
      height: 4.8rem;
      width: 12rem;

      @media #{$medium-screen} {
        width: 10rem;
        padding: 1.2rem 0;
      }

      @media #{$xlarge-screen} {
        width: 12rem;
        padding: 1.2rem 3rem;
      }
    }
  }

  &.mn_isIdentified .mn_userSection {
    border-right: .75px solid rgba(212, 212, 208, .5);
  }

  /* Extra Rewards Dropdown section */
  .mn_headerExtraRewardsSection {
    .mn_navigationDropdown {
      right: -10rem;
      width: 38.5rem;
    }
  }
}

/* Secondary navigation search bar for tablet and small desktop */
.mn_secondaryNavSearchBar {
  .mn_searchBar {
    width: 27.5rem;
    padding: 0;

    &:before {
      display: none;
    }

    .mn_hybridSearchForm {
      .mn_dropDownWrap {
        left: -10rem;
        right: -2rem;
        top: 1.8rem;
      }

      .mn_searchInput {
        font-size: 1.2rem;
        height: 2.4rem;
        padding: .4rem 3.5rem .4rem .2rem;
        display: block;
      }

      .mn_searchSubmit {
        position: absolute;
        top: 0;
        right: 0;
        height: 2.4rem;
        line-height: 2.4rem;
        font-size: 1.6rem;
        width: 1.6rem;
      }
    }
  }
}
