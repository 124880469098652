@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Regular-webfont.eot');
  src:
    url('../fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Regular-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Semibold-webfont.eot');
  src:
    url('../fonts/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Semibold-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Semibold-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Semibold-webfont.svg#open_sanslight') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Bold-webfont.eot');
  src:
    url('../fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Bold-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Bold-webfont.svg#open_sanslight') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('../fonts/OpenSans-Light-webfont.eot');
  src:
    url('../fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Light-webfont.woff') format('woff'),
    url('../fonts/OpenSans-Light-webfont.ttf') format('truetype'),
    url('../fonts/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
  font-weight: normal;
  font-style: normal;
}
