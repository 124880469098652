@import '~org/styles/variables';

.mn_navOffersTOF {
  a {
    position: relative;

    .mn_extraRewardsPill {
      left: 12rem;
      transform: none;
      width: 6.8rem;
    }
  }
}
