@import '~core/styles/variables';

/* Screen sizes */
$xlarge: 1260px;

/* Colors */
$brandPrimaryBlue: #006080;
$brandBlack: #000;
$brandWhite: #fff;
$brandGray: #e6e6e6;
$brandLightBlue: #62a9e3;
$brandDarkBlue: #0172ab;
$brandRed: #cd202c;
$brandPrimaryRed: #c30019;
$brandViolet: #745ac4;

$brandPrimaryColor: #6244bb;
$brandSecondaryColor: #0c2340;
$brandTertiaryColor: #e6e6e6;
$brandQuaternaryColor: #edb72b;

$brandNeutralLightColor: $brandTertiaryColor;
$brandNeutralMediumColor: #ccc;
$brandNeutralDarkColor: #333;
$brandPrimaryIconColor: $brandQuaternaryColor;

$brandOuterBorderColor: $brandGray;
$brandOuterBorder: $brandOuterBorderColor 1px solid;

/* VHP redesign colors */
$brandNeutralLightColor2: #f5f5f5;
$brandNeutralMediumColor2: #d1d3d4;
$brandNeutralDarkColor2: #9d9fa2;
$brandNeutralDarkColor3: #666;
$brandNeutralDarkColor4: #231f20;

$brandQuaternaryColor2: #f47920;
$brandLightBlue2: #009cde;
$brandMediumBlue2: #006ba6;
$brandMediumBlue3: #263779;
$brandDarkBlue2: #2172ba;

// Custom vhp variables
$homeBodyBackground: (small: #fff, medium: #f7f7f7);
$homeContentSectionBackground: #f2f2f2;

/* Instore Design */
$brandSecondaryColor2: #024;
$brandNeutralWhite: #bcbec0;
$brandNeutralDarkColor5: #b1b4b6;
$brandNeutralWhite2: #c7c8ca;
$brandNeutralDarkColor6: #7c848a;
$brandNeutralDarkColor7: #444;
$brandInstoreBoxShadow1: rgba(0, 0, 0, .2);
$brandInstoreBoxShadow2: rgba(0, 0, 0, .19);
$brandInstoreBoxShadow3: rgba(0, 0, 0, .5);
$brandInstoreTransparentGreyBackground: rgba(0, 34, 68, .55);
$brandInstoreNeutralDarkColor7: #4a4a4a;
$brandNeutralWhite3: #bcbcbc;
$brandNeutralWhite4: #f9f9f9;

/* Typography */
$brandTextColor: #000;
$brandTextColorInverse: #fff;
$brandTextFontSize: (small: 1.5rem, medium: 1.4rem);
$brandFontFamily: 'OpenSans', Helvetica, Arial, sans-serif;
$brandFontFamilyLight: 'OpenSans-Light', Helvetica, Arial, sans-serif;

$brandLinkColor: #006080;
$brandLinkColorHover: $brandLinkColor;

$brandH1FontFamily: $brandFontFamily;
$brandH1FontWeight: 500;
$brandH2Color: #000;
$brandH2FontFamily: $brandFontFamily;
$brandH2FontSize: (small: 2.4rem);
$brandH2LineHeight: (small: 3.4rem);
$brandH2FontWeight: 500;
$brandH3FontFamily: $brandFontFamily;
$brandH3FontWeight: 500;

$brandBorderColor: #e5ecf3;
$brandBorder: $brandBorderColor solid 1px;

/* Button */
$brandButtonPadding: (small: 1.25rem 3rem);
$brandButtonFontFamily: 'OpenSans';
$brandButtonFontSize: 1.6rem;
$brandButtonFontWeight: 500;
$brandButtonColor: $brandWhite;
$brandButtonBackgroundColor: $brandPrimaryColor;
$brandButtonBackgroundGradient: $brandButtonBackgroundColor, darken($brandButtonBackgroundColor, 5%);
$brandButtonBorder: 2px solid #745ac4;
$brandButtonBorderRadius: .4rem;
$brandButtonShadow: none;
// Hover
$brandButtonColorHover: $brandWhite;
$brandButtonBackgroundColorHover: #412d7d;
$brandButtonBorderColorHover: #412d7d;
$brandButtonBackgroundGradientHover: none;
// Active
$brandButtonColorActive: $brandWhite;
$brandButtonBackgroundColorActive: #412d7d;
$brandButtonBorderColorActive: #412d7d;
$brandButtonBackgroundGradientActive: none;
// Inverted
$brandButtonInvertedBackground: $brandWhite;
$brandButtonInvertedColor: $brandPrimaryColor;
$brandButtonInvertedBorderColor: 2px solid $brandPrimaryColor;

/* Carousel */
$carouselDotActiveBackground: $brandPrimaryColor;
$carouselArrowFontFamily: 'UnitedIcons';
$carouselArrowPrevAfterContent: '\e900';
$carouselArrowNextAfterContent: '\e90a';
$carouselInvertedDotActiveBackground: $brandPrimaryColor;
$carouselCounterArrowFontSize: 1.2rem;
$carouselCounterNumberPositionTop: -.1rem;
$carouselCounterColor: $brandNeutralDarkColor3;
$carouselArrowFontSize: 1.8rem;
$carouselCounterArrowColor: $brandNeutralDarkColor3;
$carouselCounterNumberSpanPadding: 0 .2rem;

/* Dropdown Menu */
$dropdownMenuBackground: $brandWhite url('~org/assets/images/dropdown.png') no-repeat right center;
$dropdownMenuBackgroundSize: 2.5rem 3rem;
$dropdownMenuAppearance: none;

/* Pagination */
$paginationButtonPageBackground: $brandNeutralMediumColor;
$paginationButtonPageBorderRadius: 50%;
$paginationButtonPageWidth: 1.5rem;
$paginationButtonPageHeight: 1.5rem;
$paginationButtonPageFontSize: 0;
$paginationButtonPageLineHeight: 0;
$paginationButtonPageMargin: 0 .5rem;
$paginationButtonPageBorder: 0;
$paginationButtonPagePadding: 0;
$paginationButtonPageHoverBackground: #a7a7a7;
$paginationButtonPageActiveBackground: $brandPrimaryColor;
$paginationButtonPageActiveBorder: none;
$paginationButtonPrevNextPosition: relative;
$paginationButtonPrevNextFontSize: 0;
$paginationButtonPrevNextLineHeight: 0;
$paginationButtonPrevNextMargin: 0 .5rem;
$paginationButtonPrevNextHoverColor: $brandPrimaryBlue;
$paginationButtonPrevNextAfterFontFamily: 'UnitedIcons';
$paginationButtonPrevNextAfterFontSize: 1.8rem;
$paginationButtonPrevNextAfterPosition: absolute;
$paginationButtonPrevNextAfterPositionTop: 50%;
$paginationButtonPrevNextAfterTransform: translateY(-50%);
$paginationButtonNextAfterContent: '\e90a';
$paginationButtonNextAfterPositionLeft: 0;
$paginationButtonPrevAfterContent: '\e900';
$paginationButtonPrevAfterPositionRight: 0;

/* Rebates */
$rebateColor: $brandNeutralDarkColor3;
$rebateElevationColor: #666;
$rebateElevationStrikethroughPrimary: 1px solid #666;
$rebateElevationStrikethroughSecondary: 1px solid #fff;
$rebateValueColor: #006080;
$rebateValueWithCurrencyFontWeight: 500;
$rebateCurrencyColor: $rebateValueColor;
$rebateCurrencyFontWeight: 500;
$premierRebateElevationColor: #666;

$rebateV2NewValueFontColor: #006080;

/* Header */
$headerTextColor: $brandWhite;
$headerPrimaryBackgroundColor: $brandPrimaryColor;
$dropdownBackgroundColor: $brandSecondaryColor;
$dropdownBorderColor: #697d91;
$dropdownBorderInnerColor: #003057;
$listItemActiveBackgroundColor: transparent;
$listItemLinkHoverColor: $brandLightBlue;
$sidebarSlideAnimationDuration: .5s;

/* Footer */
$brandFooterText: #666;

/* Error Page */
$errorSectHeadFontSize: (small: 2rem, medium:1.5rem, large:1.5rem);

/* Tabbed Content */
$tabbedContentMenuItemBackgroundColor: #fff;
$tabbedContentMenuItemColor: #000;
$tabbedContentContentTabsFontSize: 1.6rem;
$tabbedContentContentTabsLineHeight: 1.9rem;
$tabbedContentActiveMenuItemBorder: 2px solid #6244bb;
$tabbedContentMenuItemBorder: 1px solid #666;
$tabbedContentMenuItemBorderRadius: 4px;

/* Search form */
$hybridSearchFormInputColor: #fff;
$hybridSearchFormInputBorder: solid #d4d4d0;
$hybridSearchFormInputBorderWidth: 0 0 .75px;
$hybridSearchFormInputTextTransform: uppercase;
$hybridSearchFormSubmitButtonColor: #fff;
$hybridSearchFormFocusBorder: none;
$hybridSearchFormInputPadding: .4rem 3.5rem .4rem 1.5rem;
$hybridSearchFormPositionLeft: (small: 0, medium: -5rem, large: -6.5rem);
$hybridSearchFormPositionRight: (small: 0, medium: -5rem, large: -6.5rem);
$hybridSearchFormPositionTop: (small: 0, medium: 5.5rem, large: 5rem);
$hybridSearchFormPlaceholderColor: #fff;
$hybridSearchFormPlaceholderLetterSpacing: 1px;
$hybridSearchFormPlaceholderTextTransform: uppercase;

/* Quick search */
$quickSearchResultsBackground: #343642;
$quickSearchResultsBackgroundImage: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
$quickSearchMatchHighlightColor: #fff;
$quickSearchInstoreIconUrl: url('~org/assets/images/in-store-quick-find-white.svg');
$quickSearchInstorePositionLeft: 1.2rem;
$quickSearchInstorePositionRight: auto;
$quickSearchInstoreDividerFontWeight: 700;
$quickSearchInstoreDividerColor: #fff;
$quickSearchInstoreDividerMargin: 1rem 0 1rem .5rem;
$quickSearchMatchTextColor: #fff;
$quickSearchMatchTextFontSize: 1.2rem;
$quickSearchMatchTextLineHeight: 2.4rem;
$quickSearchMatchTextLetterSpacing: 1.03px;
$quickSearchMatchTextTextTransform: uppercase;
$quickSearchFooterProductLinkWrapPadding: .5rem 5%;
$quickSearchFooterProductLinkWrapBorderTop: 1px solid #9ea6a8;
$quickSearchFooterProductLinkWrapMargin: 0;
$quickSearchFooterProductLinkColor: #fff;
$quickSearchFooterProductLinkHoverColor: #fff;
$quickSearchFooterProductLinkWrapBackground: rgba(0, 0, 0, .8) linear-gradient(180deg, #000 0%, rgba(40, 41, 62, .4) 0%, rgba(125, 129, 195, .1) 0%, rgba(125, 129, 195, 0) 100%);
$quickSearchMerchantRebateColor: $brandWhite;
$quickSearchMerchantRebateFontSize: 1.2rem;
$quickSearchMerchantRebateFontWeight: 500;
$quickSearchMerchantRebateValueColor: $brandWhite;
$quickSearchMerchantRebateValueFontWeight: 500;
$quickSearchResultsBorderRadius: 4px;

/* Recent Stores */
$recentStoresMargin: 0 0 1rem 0;
$recentStoresPadding: 0;
$recentStoresBackground: ();
$recentStoresFlyoutBorder: 2px solid $brandPrimaryColor;
$recentStoresFlyoutHeadBgColor: $brandPrimaryColor;
$recentStoresFlyoutHeadBorder: 2px solid $brandPrimaryColor;
$recentStoresFlyoutH2Color: $brandWhite;
$recentStoresFlipTileRebateColor: $brandWhite;
$recentStoresFlipTileRebateValue: $brandWhite;
$recentStoresFlipTileRebateElevationOldValueDisplay: none;
$recentStoresFlipTileRebateElevationNowPrefixDisplay: none;
$recentStoresFlipTileRebateTieredPrefixFirstLetterTextTransform: uppercase;
$recentStoresFlipTileItemBorderBottom: none;
$recentStoresTitleFontSize: 1.4rem;
$recentStoresTitleHeadingMargin: 0 1.5rem;
$recentStoresTitleHeadingTextTransform: uppercase;
$recentStoresTitleHeadingLetterSpacing: 1.05px;
$recentStoresTitleMargin: 0;
$recentStoreShopNowButtonWidth: 15rem;
$recentStoreShopNowButtonHeight: 3.5rem;
$recentStoreShopNowButtonLineHeight: 3.1rem;
$recentStoreShopNowButtonFontSize: 1.6rem;
$recentStoresViewAllLinkBottomFontSize: 1.4rem;
$recentStoresInSearchBackground: ();
$recentStoresContentMargin: 0 -1.5rem;
$recentStoresTitleHeadingColor: #fff;
$recentStoresTitleHeadingMargin: 0 0 1.5rem 0;
$recentStoresViewAllLinkBottomColor: #fff;
$recentStoresViewAllLinkBottomMargin: 1.5rem 0 0 0;
$recentStoresViewAllLinkTextDecoration: underline;
$recentStoresViewAllLinkTextDecorationHover: none;

/* Similar stores */
$similarStoresMargin: 0 0 1rem 0;
$similarStoresPadding: 2rem .7rem;
$similarStoresTitleFontSize: 1.4rem;
$similarStoresTitleColor: #fff;
$similarStoresTitleMargin: 0;
$similarStoresTitleHeadingMargin: 0 0 1.5rem 0;
$similarStoresTitleHeadingTextTransform: uppercase;
$similarStoresTitleHeadingLetterSpacing: 1.05px;
$similarStoreMargin: 0 0 -1px 0;
$similarStoreOverflow: hidden;
$similarStoreBorder: #d4d4d0 solid 1px;
$similarStoreBorderRadius: 0;
$similarStoreRebateTextAlign: right;
$similarStoresRebateFontWeight: 500;
$similarStoreBorderWidth: 1px 0 1px 0;
$similarStorePadding: 0 1.5rem;
$similarStoreLogoPadding: .2rem;
$similarStoreLogoBorderRadius: .2rem;
$similarStoreLogoMargin: 0 auto 0 1rem;
$similarStoresBackground: ();
$similarStoresContentMargin: 0 -1.5rem;
$similarStoresPadding: 0;
$similarStoreBackground: ();

/* Pill */
$pillLabelFontSize: 1.2rem;
$pillBackground: $brandSecondaryColor;
$pillLabelTextTransform: uppercase;
$pillLabelLetterSpacing: (small: .9px, medium: .9px);
$pillLabelAfterBackground: url('../assets/images/plane-white.svg') no-repeat no-repeat;
$pillLabelAfterPositionRight: -.5rem;
$pillLabelAfterMargin: 0 0 -.5rem;

/* New Pill */
$newPillWidth: 6.4rem;
$newPillHeight: 2.2rem;

/* Section Title */
$sectionTitleH2FontSize: (small: 3.2rem, medium: 3.2rem);
$sectionTitleColor: (small: #000, medium: #000);
$sectionTitleH2FontFamily: $brandFontFamily;
$sectionTitleH2TextAlign: (small: left);
$sectionTitleLinkAfterContent: '\e90a';
$sectionTitleLinkAfterFontFamily: 'UnitedIcons', serif;
$sectionTitleLinkAfterFontSize: 1rem;

/* Merchant Tile */
$merchantTileBorderColor: $brandNeutralMediumColor;
$merchantTileBorder: (small: .25px solid $merchantTileBorderColor, medium: 1px solid $merchantTileBorderColor);
$merchantTileItemBorder: (small: 2px solid $brandOuterBorderColor, medium: 2px solid $brandOuterBorderColor);
$merchantTileRebateHoverTextDecoration: underline;
$merchantTileFavoriteIconPositionTop: (small: .2rem, medium: 0);
$merchantTileRebateHeight: (small: 6.7rem, medium: 6.4rem);
$merchantTileFavoriteIconPositionRight: (small: 0, medium: 0);

/* Merchant With Offer Tile */
$merchantWithOfferCountBorderRadius: 5px;
$merchantWithOfferCountBoxShadow: (small: 1px 1px 3px 0 rgba(0, 0, 0, .2));
$merchantWithOfferCountTileBorder: 2px solid $brandTertiaryColor;
$merchantWithOfferCountTileBorderRadius: 1px;
$merchantWithOfferCountRebateNewValueFontSize: 1.4rem;

/* Direct Merchants */
$merchantTileDirectMerchantLogoBackgroundColor: #f7f7f7;
$merchantTileDirectMerchantLogoColor: #0033a0;

/* Info bar slider */
$infoBarSliderOfferWrapBoxShadow: 1px 1px 4px 0 rgba(0, 0, 0, .2), inset 0 0 0 2px #fff, inset 0 0 0 4px #e6e6e6;
$infoBarSliderOfferBorderRadius: 4px;
$infoBarSliderOfferWrapRadius: 4px;
$infoBarSliderOfferItemMargin: 0 0 0 1rem;
$infoBarSliderOfferItemExpireColor: $brandNeutralDarkColor3;
$infoBarSliderOfferLabelLetterSpacing: 1px;
$infoBarSliderOfferPadding: 0 1rem;
$infoBarSliderOfferBorderRadius: 6px;
$infoBarSliderOfferPillBottom: 6.7rem;
$infoBarSliderOfferPillWidth: calc(100% - 6px);
$infoBarSliderOfferRebateNewValueFontSize: 1.4rem;
$infoBarSliderOfferRebateBlockPadding: 1.5rem 1rem 1rem;
$infoBarSliderFavoriteIconRight: .5rem;
$infoBarSliderFavoriteIconTop: .7rem;
$infoBarSliderOfferRolloverCopyLineHeight: 2.4rem;

/* Sponsored Deals */
$sponsoredDealEndingSoonColor: $brandQuaternaryColor2;

/* Sweeps */
$sweepStakesHeaderBackgroundOld: url('~org/assets/images/sweeps/sweeps_old.jpg');
$sweepStakesHeaderBackground: url('~org/assets/images/sweeps/sweeps.jpg');
$sweepsPastWinnerBackgroundImage: url('~org/assets/images/sweeps/past_winner.jpg');
$sweepStakesQuestionIcon: url('~org/assets/images/sweeps/question.png');
$sweepStakesCheckInIcon: url('~org/assets/images/sweeps/check_in.svg');

/* For You */
$forYouBonusTabBonusMilesInactiveBackground: url('~org/assets/images/bonus/UA-bonus-miles-inactive.svg');
$forYouBonusTabBonusMilesActiveBackground: url('~org/assets/images/bonus/UA-bonus-mile-active.svg');
$forYouPendingTabBonusPostDateBeforeBackground: url('~org/assets/images/bonus/UA-Cal.svg');
$forYouPendingTabEmptyStateOfyBackground: url('~org/assets/images/bonus/empty-state-ua-ofy.svg');
$forYouPendingTabSuccessCheckBackground: url('~org/assets/images/bonus/UA-success-check.svg');
$forYouPendingTabPublicBonusBackground: url('~org/assets/images/bonus/UA-Public-Bonus.png');

/* Instore Map Sticky */
$instoreMapStickyNewColor: $brandQuaternaryColor2;
$instoreMapStickyColor: #fff;
$instoreMapStickyBackground: linear-gradient(180deg, #6244bb 0%, #402d7e 100%);

/* For You Sign In */
$forYouSignInButtonBackgroundColor: $brandLightBlue2;
$forYouSignInButtonColor: $brandWhite;

/* Email OptIn */
$emailSubscribeHeadingFont: (small: bold 3.5rem/1.2 $brandFontFamily, medium: bold 3.5rem/1.2 $brandFontFamily);
$emailSubscribeContentFont: (small: normal 2.2rem/1.4 $brandFontFamilyLight, medium: ());
$emailSubscribeModalBoxShadow: (small: none);
$emailSubscribeModalOutline: unset;
$emailSubscribeCloseColor: (small: $brandWhite, medium: $brandBlack);
$emailSubscribePanelTextAlign: center;

/* Overlays */
$lightboxBorderRadius: (small: 5px);
$lightboxOverlayBackground: (small: rgba(0, 34, 68, .9), medium: rgba(0, 0, 0, .6));
$lightboxCloseIconFontFamily: 'UnitedIcons';
$lightboxCloseIconContent: '\e90c';
$lightboxShadow: 0 2px 24px 0 rgba(0, 0, 0, .5), inset 0 0 0 2px #fff, inset 0 0 0 4px $brandBorderColor;
$lightboxMaxWidth: 100%;

/* Modal */
$modalWrapperBackground: (small: rgba(0, 34, 68, .6), medium: rgba(0, 0, 0, .6));
$modalBoxShadow: (small: (1px 1px 4px 0 rgba(0, 0, 0, .2), inset 0 0 0 2px #fff, inset 0 0 0 4px $brandGray, 0 2px 24px 0 rgba(0, 0, 0, .5)), medium: ());
$modalBorderRadius: (small: 0, medium: .5rem);

$emailSubscribeHeadingDisplay: (small: block, medium: none);
$emailSubscribeContentColor: (small: $brandTextColorInverse, medium: $brandTextColor);
$emailSubscribeOptinMargin: (medium: 5rem 0 0, large: 6rem 0 0);
$emailSubscribeOptInImageBackground: url('~org/assets/images/email.png') no-repeat 50% 0/contain;
$emailSubscribeOptInImageHeight: 13.3rem;
$emailSubscribeOptInImageWidth: 100%;
$emailSubscribeOptInImageMargin: (small: 5rem 0 3rem, medium: 0 0 3rem);
$emailSubscribeOptInH2Margin: (medium: 8rem 0 0, large: 7rem 0 1rem);
$emailSubscribeSubmitMargin: (small: 0 auto 1rem auto, medium: 4rem auto 1rem auto, large: 3rem auto 1rem auto);
$emailSubscribeSubmitWidth: (small: auto, medium: 33rem);
$emailSubscribeSubmitHeight: (small: auto, medium: 4.8rem);
$emailSubscribeTextWidth: 350px;
$emailSubscribeTextMargin: .7rem auto 1.5rem;
$emailSubscribeTextFontSize: 1.6rem;
$emailSubscribeTextFontWeight: (medium: 600, large: 500);
$emailSubscribeTextLineHeight: 2.4rem;
$emailSubscribeCancelLinkColor: (small: $brandTextColorInverse, medium: $brandTextColor);

/* Offer Overlay */
$merchantOverlayHeadLogoOffsetTop: (small: 3rem, medium: -4rem);
$merchantOverlayHeadLogoOffsetDisplay: (small: none, medium: block);
$merchantOverlayHeadLogoOffsetPosition: absolute;
$merchantOverlayHeadLogoOffsetRight: .4px;
$merchantOverlayHeadLogoOffsetAfterTop: 1px;
$merchantOverlayHeadLogoOffsetZindex: 0;
$merchantOverlayHeadLogoOffsetHeight: 37px;
$merchantOverlayHeadLogoOffsetWidth: 154px;
$merchantOverlayHeadLogoOffsetBorderColor: #e6e6e6;
$merchantOverlayHeadLogoOffsetBorderStyle: solid;
$merchantOverlayHeadLogoOffsetBorderWidth: 2px 2px 0 2px;
$merchantOverlayHeadLogoOffsetBorderRadius: 1px 1px 0 0;
$merchantOverlayFavoriteIconPositionRight: (small: .5rem, medium: 0);
$merchantOverlayFavoriteIconPositionTop: (small: .5rem, medium: .3rem,);
$merchantOverlayFavoriteIconWidth: (small: 2.3rem, medium: 2.3rem);
$merchantOverlayFavoriteIconHeight: (small: 2.3rem, medium: 2.3rem);
$merchantOverlayHeadLogoPadding: (small: 1.5rem 2rem, medium: 2.4rem 1.9rem 0);
$merchantOverlayContentPadding: (small: 0, medium: 0 2rem 1rem);
$merchantOverlayDescriptionMargin: (small: 0,  medium: 2rem 0 0);
$merchantOverlayOfferTitleFont: (small: 600 2rem/3.2rem $brandFontFamily,  medium: 600 2.2rem/3rem $brandFontFamily);
$merchantOverlayOfferTitleColor: (small: $brandTextColor);
$merchantOverlayHeadRebatePadding: (small: 1rem 0 1rem, medium: 2rem 2rem);
$merchantOverlayHeadRebateBorderBottom: (small: 1px solid rgba(0, 0, 0, .1), medium: none);
$merchantOverlayHeadRebateBorderTop: (small: none, medium: 1px solid rgba(0, 0, 0, .1));
$merchantOverlayHeadOfferExpiresFontSize: (small: 1.2rem);
$merchantOverlayHeadOfferExpiresMargin: (small: 0, medium: 0);
$merchantOverlayHeadOfferSeparatorDisplay: (small: none, medium: ());
$merchantOverlayHeadRebateMargin: (small: 0);
$merchantOverlayHeadRebateFontSize: (small: 1.4rem, medium: 1.6rem);
$merchantOverlayRebateOldValueDisplay: (small: block, medium: inline);
$merchantOverlayRebateOldValueFontSize: (small: 1.2rem, medium: 1.6rem);
$merchantNoRebateColor: $rebateElevationColor;
$merchantOverlayMerchantRebateFontSize: 1.4rem;
$merchantOverlayHeadRebateFontWeight: (small: 600);
$merchantOverlayRebateValueWithCurrencyFontWeight: 500;
$merchantOverlayRebatePrefixColor: (small: $rebateValueColor);
$merchantOverlayCouponIntroFontSize: (small: 1.2rem, medium: 1.2rem);
$merchantOverlayCouponIntroMargin: (small: 2rem 0 3rem, medium: 1.8rem 0);
$merchantOverlayCouponControlsJustifyContent: center;
$merchantOverlayCouponControlsGap: (small: 1.5rem);
$merchantOverlayCouponCodeInputMinWidht: (small: 22rem);
$merchantOverlayCouponCodeInputMaxWidht: (small: 22rem);
$merchantOverlayCouponCodeCopyWidth: (small: 8rem);
$merchantOverlayCouponCodeInputHeight: (small: 5rem);
$merchantOverlayCouponCodeIsCopiedBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .5);
$merchantOverlayCouponCodeCopyHoverBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .5);
$merchantOverlayShopNowMargin: (small: 2rem 0, medium: ());
$merchantOverlayShopNowFont: (small: 400 1.6rem/1.7rem $brandFontFamily);
$merchantOverlayShopNowBorder: none;
$merchantOverlayShopNowPadding: (small: 1.65rem 0, medium: ());
$merchantOverlayShopNowBorderRadius: 5px;
$merchantOverlayShopNowHoverBorderColor: $brandButtonBorderColorHover;
$merchantOverlayShopNowHoverBackground: $brandButtonBackgroundColorHover;
$merchantOverlayShopNowHoverBoxShadow: 1px 1px 4px 0 rgba(0, 0, 0, .5);
$merchantOverlayFooterLinkFontWeight: (small: 500, medium: 400);
$merchantOverlayFooterMerchantTermsMargin: (small: .5rem 0 0, medium: ());
$merchantOverlayFooterTermsFontSize: (small: 1.2rem, medium: ());
$merchantOverlayShopNowMinWidth: (small: 25.8rem,  medium: 100%);
$merchantOverlayDecorationDisplay: (small: block, medium: none);
$merchantOverlayDecorationBorderWidth: 2px 2px 0;
$merchantOverlayDecorationHeight: 146px;
$merchantOverlayDecorationBackground: linear-gradient(0deg, $brandPrimaryColor 0%, $brandViolet 100%);
$merchantOverlayDecorationPosition: relative;
$merchantOverlayDecorationAfterContent: '';
$merchantOverlayDecorationAfterDisplay: block;
$merchantOverlayDecorationAfterWidth: 100%;
$merchantOverlayDecorationAfterHeight: 56px;
$merchantOverlayDecorationAfterBackground: $brandPrimaryColor;
$merchantOverlayDecorationAfterPosition: absolute;
$merchantOverlayDecorationAfterPositionBottom: 0;
$merchantOverlayDisclaimerOrder: (small: 3, medium: 2);
$merchantOverlayOfferTitleOrder: (small: 2, medium: 1);
$merchantOverlayHeadOfferExpiresOrder: (small: 4, medium: 3);
$merchantOverlayPremierMerchantRebateOrder: (small: 1, medium: 4);
$merchantOverlayPremierMerchantRebateMargin: (small: 1rem 0 0, medium: 1.5rem 0 0);
$merchantOverlayExtraPillLabelFontSize: 1rem;
$merchantOverlayExtraPillPositionTop: 15rem;
$merchantOverlayDescriptionDisplay: (small: flex, medium: flex);
$merchantOverlayDescriptionFlexDirection: (small: column, medium: column);
$merchantOverlayOfferTitleMargin: (small: 2rem 0 1rem, medium: 2rem 0 2rem);
$merchantOverlayRebatePrefixFontWeight: (small: 500, medium: ());
$merchantOverlayRebateOldValueAfterContent: '';
$merchantOverlayDisclaimerFontSize: (small: 1.2rem, medium: ());
$merchantOverlayDisclaimerColor: (small: $brandNeutralDarkColor3, medium: $brandNeutralDarkColor3);
$merchantOverlayFooterTermsFontWeight: (small: 400, medium: 500);
$merchantOverlayDisclaimerMargin: 0 auto;
$merchantOverlayCouponMargin: 1rem 0 2rem;
$merchantOverlayDisclaimerWidth: ();
$merchantOverlayArrowLinkDisplay: (small: none, medium: inline-block);

/* balloon-css */
$balloon-bg: #dadbec;
$balloon-color: #000;
$balloon-border: none;
$balloon-svg-arrow-width: 16;
$balloon-svg-arrow-height: 6;
$balloon-arrow-height: 6px;
$balloonSVGFillColor: '%23dadbec';
$balloonSVGStrokeColor: '%23dadbec';

/* Onboarding */
$onboardingFontFamily: ();
$onboardingBoxShadowMedium: 1px 1px 4px 0 rgba(0, 0, 0, .2), inset 0 0 0 2px #fff, inset 0 0 0 4px #e6e6e6;
$onboardingBoxShadowLarge: 1px 1px 4px 0 rgba(0, 0, 0, .2), inset 0 0 0 2px #fff, inset 0 0 0 4px #e6e6e6, 0 2px 24px 0 rgba(0, 0, 0, .5);
$onboardingH2FontFamily: ();
$onboardingH2Margin: (medium: 0 0 1.9rem);
$onboardingH2LineHeight: (small: 4.3rem, medium: 4rem, large: 3.2rem);
$onboardingFavoritesHeadingLineHeight: (small: 3.2rem, medium: 4rem, large: 3.2rem);
$onboardingFavoritesPickFavoritesMargin: (small: 1rem 0 0 0, medium: auto, large: auto);
$onboardingFavoriteStepBottomContentPadding: (small: 2rem 2rem 5rem 2rem, medium: ());
$onboardingBrandLogoBackground: url('~org/assets/images/logo_reverce.svg') no-repeat center / auto 100%;
$onboardingGetTheButtonBackground: url('~core/modules/Onboarding/assets/images/button.svg') no-repeat center;
$onboardingGetTheButtonHeight: (medium: 14rem, large: 156px);
$onboardingGetTheButtonWidth: (medium: 15rem, large: 181px);
$onboardingOptInImageBackground: url('~org/assets/images/email.png') no-repeat 50% 0/contain;
$onboardingBrandLogoHeight: 60px;
$onboardingBrandLogoWidth: 100%;
$onboardingBrandLogoMargin: (medium: 0 auto 15rem, large: 0 auto 26px);
$onboardingBottomContentPadding: (small: 2rem 2rem 5rem 2rem, medium: 2rem 2rem 8rem);
$onboardingH2FontWeight: 500;
$onboardingPLineHeight: (small: 2.4rem, medium: 2.4rem, large: 2.4rem);
$onboardingPFontWeight: 500;
$onboardingPColor: #000;
$onboardingStepButtonHeight: 48px;
$onboardingStepButtonFontWeight: 500;
$onboardingStepButtonFontSize: 1.6rem;
$onboardingStepButtonMargin: (medium: 1.5rem 0 0, large: 0 auto 3rem);
$onboardingStepLaterLinkColor: $brandPrimaryBlue;
$onboardingStepLaterLinkFontSize: 1.4rem;
$onboardingStepLaterLinkFontWeight: normal;
$onboardingStepLaterLinkTextDecoration: (small: none, medium: underline);
$onboardingStepLaterLinkMargin: (small: 0 auto, medium: 1rem auto);
$onboardingTurnOffAlertsColor: #000;
$onboardingTurnOffFontSize: (large: 1.4rem);
$onboardingTurnOffLineHeight: (large: 1.8rem);
$onboardingTurnOffMargin: (large: .3rem 0 0);
$onboardingGetTheButtonMarginTop: 50px;
$onboardingStepButtonOptinDisableBackground: $brandNeutralMediumColor;
$onboardingStepButtonOptinDisableColor: $brandWhite;
$onboardingStepButtonOptinDisableBorder: 2px solid $brandNeutralMediumColor;
$onboardingFavoriteStepButtonMargin: (medium: 1.5rem 0 0, large: 0 auto 1rem);
$onboardingPageCounterPosition: absolute;
$onboardingPageCounterPositionBottom: (small: 2rem, medium: 4rem);
$onboardingFavoritesPageCounterPositionBottom: (small: 1%, medium: 2rem);
$onboardingPageCounterPositionTop: auto;
$onboardingPageCounterPositionLeft: 50%;
$onboardingPageCounterPositionRight: auto;
$onboardingPageCounterTransform: translateX(-50%);
$onboardingPageCounterFontSize: 0;
$onboardingPageCounterPointerEvents: none;
$onboardingPageCounterHeight: 1rem;
$onboardingPageCounterWidth: 10rem;
$onboardingPageCounterBackgroundImage: radial-gradient(#ccc 5px, transparent 5.1px);
$onboardingPageCounterBackgroundSize: 2rem 2rem;
$onboardingPageCounterBackgroundPosition: -.5rem -.5rem;
$onboardingPageCounterOverflow: hidden;
$onboardingPageCounterAfterContent: '';
$onboardingPageCounterAfterPosition: absolute;
$onboardingPageCounterAfterPositionTop: 0;
$onboardingPageCounterAfterPositionLeft: 0;
$onboardingPageCounterAfterWidth: 10px;
$onboardingPageCounterAfterHeight: 10px;
$onboardingPageCounterAfterBackground: $brandPrimaryColor;
$onboardingPageCounterAfterBorderRadius: 50%;
$onboardingPageCounterUseDots: true;
$onboardingFavoritesPickFavoritesBackground: (small: -webkit-radial-gradient(50% 0%, 50% 150px, rgba(182, 184, 220, .5) 0%, rgba(255, 255, 255, 0) 100%));
$onboardingFavoritesMerchantLogoBorder: #ccc solid 1px;
$onboardingFavoritesMerchantLogoBorderRadius: 4px;
$onboardingFavoritesMerchantLogoBoxShadow: inset 0 0 0 2px #fff, inset 0 0 0 4px $brandBorderColor;
$onboardingFavoritesCarouselArrowNextBeforeContent: '';
$onboardingFavoritesCarouselArrowNextBeforeBackground: url('~core/assets/images/arrow-right.svg') 0 0 no-repeat;
$onboardingFavoritesCarouselArrowPrevBeforeContent: '';
$onboardingFavoritesCarouselArrowPrevBeforeBackground: url('~core/assets/images/arrow-left.svg') 0 0 no-repeat;
$onboardingFavoritesCarouselArrowDisplay: inline-block;
$onboardingFavoritesCarouselArrowHeight: 20px;
$onboardingFavoritesCarouselArrowWidth: 20px;
$onboardingFavoritesCarouselArrowNextPositionRight: .7rem;
$onboardingFavoritesCarouselArrowPrevPositionLeft: 1.4rem;
$onboardingFavoritesCarouselArrowNextTop: calc(50% - 30px);
$onboardingFavoritesCarouselArrowPrevTop: calc(50% - 30px);
$onboardingFavoritesCarouselArrowPrevZIndex: 1;
$onboardingWelcomeStepHeaderMargin: (small: 15rem 0 2rem, large: 10rem 0 1rem);
$onboardingButtonStepListPadding: (small: 2rem 1rem 0);
$onboardingButtonStepHeadingMargin: (small: 3rem 1rem);
$onboardingFavoritesPotentialFavoriteMargin: (medium: .7rem, large: 0);
$onboardingButtonStepButtonMargin: (medium: 1.5rem 0 0, large: 0 auto 1rem);
$onboardingButtonStepDescriptionFlexDirection: (medium: column, large: unset);
$onboardingButtonStepListWidth: (medium: 100%, large: unset);
$onboardingButtonStepBulletTextWidth: 100%;
$onboardingButtonStepBulletTextFontSize: (medium: 1.3rem, large: 1.2rem);
$onboardingCongratsHeadingPaddingTop: (small: .8rem, medium: 5rem, large: 2.8rem);
$onboardingCongratsHeadingFontWeight: (small: 500, medium: 300, large: 500);

$pageErrorBoundaryBackgroundImage: url('~org/assets/images/error-bg.jpg');
$pageErrorBoundaryColor: #fff;
$pageErrorBoundaryHeadingColor: #fff;

/* Quick Links Tray */
$quickLinksTrayBackgroundColor: url('~org/assets/images/quick_tray_back_swoosh.png');
$quickLinksTrayLinkSeparatorBorder: 1px solid rgba(0, 0, 0, .15);
$quickLinksTrayLinkFontSize: 1rem;
$quickLinksTrayLinkTextTransform: uppercase;
$quickLinksTrayLinkLetterSpacing: .5px;
$quickLinksTrayLinkFontWeight: 500;
$quickLinksTrayIconMargin: 0 auto 7px;
$quickLinksTrayIconExtraRewardsUrl: url('~org/assets/images/PlanePlusIcon.svg');
$quickLinksTrayIconExtraRewardsHeight: 17px;
$quickLinksTrayIconAccountUrl: url('~org/assets/images/userIcon.svg');
$quickLinksTrayIconAccountHeight: 16px;
$quickLinksTrayToggleFontSize: 1rem;
$quickLinksTrayToggleTextTransform: uppercase;
$quickLinksTrayToggleAfterFontFamily: 'UnitedIcons';
$quickLinksTrayToggleAfterContent: '\e90b';
$quickLinksSearchIconBeforeFontFamily: 'UnitedIcons';
$quickLinksSearchIconBeforeContent: '\e901';
$quickLinksTrayIconAllStoresUrl: url('~org/assets/images/icons/star_white.svg');
$quickLinksTrayIconAllStoresHeight: 1.7rem;
$quickLinksTrayLinkLineHeight: 2rem;
$quickLinksTrayHeight: 8.5rem;

/* Dynamic Html Text */
$htmlTextHeadFontSize: 3.2rem;
$htmlTextHeadFontWeight: 500;
$htmlTextFontSize: 1.2rem;
$htmlTextColor: #666;
$htmlTextLineHeight: 2.4rem;

/* Dynamic Merchants */
$dynamicMerchantBoxShadow: .5px .5px 2px 0 rgba(0, 0, 0, .2), inset 0 0 0 2px $brandWhite, inset 0 0 0 4px $brandGray;
$dynamicMerchantPadding: .4rem;
$dynamicMerchantPillLabelFontSize: 1rem;
$dynamicMerchantPillLabelLetterSpacing: .2rem;
$dynamicMerchantNavBackground: $brandSecondaryColor2;
$dynamicMerchantNavColor: $brandWhite;
$dynamicMerchantNavLabelLetterSpacing: .1rem;
$dynamicMerchantNavButtonBackground: $brandWhite;
$dynamicMerchantNavButtonBorder: 2px solid $brandNeutralDarkColor3;
$dynamicMerchantNavButtonBorderRadius: 4px;
$dynamicMerchantNavButtonFontSize: 1.6rem;
$dynamicMerchantNavButtonHeight: 4.6rem;
$dynamicMerchantNavButtonActiveBorder: 2px solid $brandPrimaryColor;
$dynamicMerchantNavDropdownMenuBackground: $brandWhite url('~org/assets/images/chevron_down_1.svg') no-repeat;
$dynamicMerchantNavDropdownMenuBackgroundSize: 1.5rem;
$dynamicMerchantNavDropdownMenuBackgroundPosition: right 10px center;
$dynamicMerchantNavDropdownMenuFontSize: 1.6rem;
$dynamicMerchantNavDropdownMenuMinHeight: 4.6rem;
$dynamicMerchantNavExpandButtonIconColor: $brandSecondaryColor2;

/* stylized banner */
$stylizedBannerCountDownClockInfoWrapBackgroundColor: #024;
$stylizedBannerCountDownClockPrefixColor: $brandWhite;
$stylizedBannerCountDownClockPrefixFontSize: (xsmall: 1rem, small: 1.2rem, medium: 1.4rem);
$stylizedBannerCountDownClockPrefixLetterSpacing: (small: .9px, medium: 1.05px);

$stylizedBannerCountDownClockUnitFontSize: (xsmall: 1rem, small: 1.2rem, medium: 1.4rem);
$stylizedBannerCountDownClockUnitColor: $brandWhite;
$stylizedBannerCountDownClockUnitLetterSpacing: (small: .9px, medium: 1.05px);

$stylizedBannerCountDownClockLineBorderRight: 1px solid #d4d4d0;
$stylizedBannerCountDownClockTimeColor: $brandWhite;
$stylizedBannerCountDownClockTimeFontSize: (xsmall: 1.5rem, small: 1.8rem, medium: 2.4rem);
$stylizedBannerCountDownClockTimeLetterSpacing: (small: .72px, medium: .96px);

$stylizedBannerHeadlineColor: $brandBlack;
$stylizedBannerHeadlineFontSize: (small: 1.4rem, medium: 1.4rem);
$stylizedBannerHeadlineLetterSpacing: (small: 1.05px, medium: 1.05px);
$stylizedBannerHeadlineLineHeight: (small: 1.9rem, medium: 1.9rem);

$stylizedBannerShortSummaryColor: $brandBlack;
$stylizedBannerShortSummaryFontSize: (small: 2.4rem, medium: 4rem);
$stylizedBannerShortSummaryLetterSpacing: 0;
$stylizedBannerShortSummaryLineHeight: (small: 3.2rem, medium: 5.6rem);

$stylizedBannerOfferTitleColor: $brandBlack;
$stylizedBannerOfferTitleFontSize: (small: 1.4rem, medium: 2rem);
$stylizedBannerOfferTitleLetterSpacing: (small: 0, medium: 0);
$stylizedBannerOfferTitleLineHeight: (small: 2.4rem, medium: 3.2rem);

$stylizedBannerShortSummaryFontWeight: 500;
$stylizedBannerCountDownClockTimeFontWeight: 500;
$stylizedBannerCountDownClockUnitFontWeight: 500;
$stylizedBannerCountDownClockLineBorderOpacity: 50%;

/* Merchant logos */
$merchantLogosSectionBackground: (small: #ededf6);

// Error page
$errorSectHeadColor: #fff;
$errorSectHeadTextAlign: center;
$errorSectHeadMargin: 0;
$errorSectHeadPadding: 0;
$errorContentColor: #fff;
$errorContentFontSize: 2rem;
$errorContentLineHeight: 3.2rem;
$errorContentFontWeight: 500;
$errorContentTextAlign: left;

/* Category merchants */
$categoryMerchantsTableToggleOffersIconContent: '\e90a';
$categoryMerchantsTableToggleOffersIconFontFamily: 'UnitedIcons';

/* Bonus tiers */
$stylizedBannerBonusTiersBackground: rgba(182, 184, 220, .25);
$stylizedBannerBonusTierCounterBackground: #111b40;
$stylizedBannerBonusTierCounterColor: #fff;
$stylizedBannerBonusTierCounterFontSize: (small: 1.6rem, medium: 2.4rem);
$stylizedBannerBonusTierRewardColor: $brandBlack;
$stylizedBannerBonusTierRewardLineHeight: (small: 3.1rem);
$stylizedBannerBonusTierPLineHeight: (small: 2.6rem);

$merchantBonusDetailsBorderRadius: 4px;
$merchantBonusDetailsBorder: 1px solid rgba(12, 35, 64, .3);
$merchantBonusTopInfoBackground: url('~org/assets/images/bonus/UA-bonus-BG.png');
$merchantBonusTopInfoCircleBackground: no-repeat center/100% url('~org/assets/images/bonus/UA-empty-state.svg');
$merchantBonusTopInfoCircleCompletedBackground: no-repeat center/100% url('~org/assets/images/bonus/UA-success-check.svg');
$merchantBonusTopInfoBackgroundStartColor: rgba(0, 0, 0, .4);
$merchantBonusTopInfoBackgroundEndColor: rgba(0, 0, 0, .4);
$merchantBonusDetailsBackgroundColor: rgba(212, 212, 208, .25);
$merchantBonusDetailsRibbonColor: #000;
$merchantBonusDetailsRibbonBackground: #00b2a9;
$merchantBonusDetailsRibbonBottomBorderColor: #130a2d;
$merchantBonusDetailsRibbonTextTransform: uppercase;
$merchantBonusDetailsRibbonLineHeight: 2.4rem;
$merchantBonusDetailsHeadingColor: $brandWhite;
$merchantBonusDetailsHeadingFontWeight: 500;
$merchantBonusDetailsHeadingLineHeight: 4.1rem;
$merchantBonusDetailsSpentInfoColor: $brandWhite;
$merchantBonusDetailsSpentInfoTitleTextTransform: uppercase;
$merchantBonusDetailsSpentInfoTitleLineHeight: 2rem;
$merchantBonusDetailsSpentInfoTitleColor: #ccc;
$merchantBonusDetailsButtonBorder: 2px solid #6244bb;
$merchantBonusDetailsButtonBackground: $brandWhite;
$merchantBonusDetailsButtonColor: #6244bb;
$merchantBonusDetailsButtonHeight: 4.8rem;
$merchantBonusDetailsButtonWidth: 25rem;
$merchantBonusDetailsButtonBorderRadius: 0;
$merchantBonusDetailsButtonFontSize: 1.6rem;
$merchantBonusDetailsButtonLineHeight: 1.4rem;
$merchantBonusDetailsBottomInfoBackgroundColor: rgba(212, 212, 208, .25);
$merchantBonusDetailsEndInfoColor: #000;
$merchantBonusDetailsTermsAndConditionsColor: #006080;
$merchantBonusDetailsSeparatorColor: #ccc;
$merchantBonusDetailsTermsAndConditionsFontSize: 1.4rem;
$merchantBonusDetailsTermsAndConditionsLetterSpacing: 0;
$merchantBonusDetailsTermsAndConditionsFontWeight: 600;
$merchantBonusDetailsSeparatorColor: #000;
$merchantBonusDetailsDescriptionLineHeight: 2.8rem;
$merchantBonusDetailsCouponCodeFontSize: 1.4rem;
$merchantBonusDetailsOfferCouponCodeMargin: 0;

/* Merchant Bonus Offers */
$bonusMerchantOfferBorder: 1px solid #0c2340;
$bonusMerchantOfferBorderRadius: 4px;
$bonusMerchantOfferBonusTagWrapBackground: #00b2a9;
$bonusMerchantOfferBonusTagTextTransform: uppercase;
$bonusMerchantOfferBottomWrapBackground: #0b2340;
$bonusMerchantOfferBottomWrapBorderRadius: 0 0 4px 4px;
$bonusMerchantOfferBonusTagWrapTextTransform: uppercase;
$bonusMerchantOfferHeadingFontSize: 2.4rem;
$bonusMerchantOfferHeadingLineHeight: 3.3rem;
$bonusMerchantOfferHeadingFontWeight: 500;
$bonusMerchantOfferInfoColor: #ccc;
$bonusMerchantOfferInfoBackground: #0b2340;
$bonusMerchantOfferInfoFontWeight: 500;
$bonusMerchantOfferInfoElevatedValueBorderTop: 1px solid #ccc;
$bonusMerchantOfferSeeDetailsLineHeight: 1.9rem;
$bonusMerchantOfferSeeDetailsFontWeight: 500;
$bonusMerchantOfferSeeDetailsAfterContent: '\e90a';
$bonusMerchantOfferSeeDetailsAfterFontFamily: 'UnitedIcons';
$bonusMerchantOfferSeeDetailsAfterFontSize: 1rem;
$bonusMerchantOfferSeeDetailsAfterPadding: 0 0 0 .5rem;
$bonusMerchantOfferRebatePrefixTextTransform: lowercase;

/* Merchant Bonus Offers on Flex page */
$bonusMerchantOfferFlexPage2UpHeadingMediumLineHeight: 4.1rem;
$bonusMerchantOfferFlexPage2UpDescriptionMediumFontSize: 2.1rem;
$merchantBonusDetailsSpentInfoFontWeight: 500;
$merchantBonusDetailsTermsAndConditionsFontWeight: 500;

/* Email unsubscribe */
$emailUnsubFontSize: (small: 1.6rem);
$emailUnsubPadding: (small: 5rem 1.5rem, medium: 6rem 1.5rem);
$emailUnsubLineHeight: (small: 2.4rem);
$emailUnsubErrorBorder: 1px solid #ccc;
$emailUnsubErrorBackground: #fff;
$emailUnsubErrorBorderRadius: 4px;
$emailUnsubErrorColor: #000;
$emailUnsubErrorPadding: 1.5rem 1.5rem 1.5rem 5rem;

/* Button */
$buttonLandingMobileHeaderBackground: ();
$buttonLandingMobileHeaderColor: ();
$buttonLandingMobileHeaderPadding: 1rem 2.2rem;
$buttonLandingMobileHeaderFontSize: 1.6rem;
$buttonLandingMobileWrapPaddingLeft: ();
$buttonLandingMobileWrapIconLeft: 1.6rem;
$buttonLandingMobileWrapIconTop: 1.9rem;
$buttonLandingMobileWrap550LineHeight: ();
$buttonLandingMobileImageWrapMargin: (small: 4rem -2.2rem 0, medium: 4rem -2.2rem 0);
$buttonLandingMobileImageWrapFloat: ();
$buttonLandingMobileImageWrapWidth: ();
$buttonLandingMobileContentWrapMargin: ();
$buttonLandingMobileContentWrapPadding: ();
$buttonLandingMobileContentH2FontSize: (small: 2.4rem, medium: 2.4rem);
$buttonLandingMobileContentH2LineHeight: (small: 1.2);
$buttonLandingMobileContentH2TextAlight: (small: left);
$buttonLandingMobileContentH2FontWeight: 500;
$buttonLandingMobileContentH2Margin: (small: 2rem 0, medium: 2rem 0);
$buttonLandingMobileContentFloat: none;
$buttonLandingMobileContentWidth: ();
$buttonLandingMobileContentMaxWidth: ();
$buttonLandingMobileContentTextAlign: (small: left);
$buttonLandingMobileContentPadding: (small: 0 2.2rem 2rem, medium: 0 2.2rem 2rem);
$buttonLandingMobileContentMargin: 0 auto;
$buttonLandingMobileContentDescMargin: 2rem 0 4rem;
$buttonLandingMobileContentDescFontSize: 1.6rem;
$buttonLandingMobileContentDescLineHeight: 3.2rem;
$buttonLandingMobileContentButtonFontSize: 1.6rem;
$buttonLandingMobileContentButtonPadding: 2.2rem 2rem;
$buttonLandingMobileFooterFontSize: 2rem;
$buttonLandingMobileFooterLineHeight: 3.2rem;
$buttonLandingMobileFooterFontWeight: 500;
$buttonLandingMobileFooterPadding: 1rem 2.2rem 3rem;
$buttonLandingMobileButtonPadding: 1.5rem 3rem;

$buttonLandingMobileChromeNoThanksLinkTextDecoration: underline;
$buttonLandingMobileChromeInfoFontWeight: 600;
$buttonLandingMobileChromeHeadingFontWeight: normal;
$buttonLandingMobileChromeHeadingFontSize: 3.2rem;
$buttonLandingMobileChromeHeadingLineHeight: 4.3rem;
$buttonInfoImageWidth: 19.3rem;

/* Button Unistall */
$buttonUninstallSectHeadBackground: $brandSecondaryColor;
$buttonUninstallSectHeadColor: $brandWhite;

/* Add to favorite pop up */
$addToFavoriteFirstTimePopUpTextAlign: center;
$addToFavoriteFirstTimePopUpModalWidth: (small: (), medium: 50rem);
$addToFavoriteFirstTimePopUpTitleColor: (small: $brandBlack, medium: $brandBlack);
$addToFavoriteFirstTimePopUpTitleFontSize: 2.4rem;
$addToFavoriteFirstTimePopUpTitleFontWeight: 500;
$addToFavoriteFirstTimePopUpTitleLineHeight: 3.2rem;
$addToFavoriteFirstTimePopUpTextColor: (small: $brandBlack, medium: $brandBlack);
$addToFavoriteFirstTimePopUpTextFontSize: 1.6rem;
$addToFavoriteFirstTimePopUpTextLineHeight: 2.4rem;
$addToFavoriteFirstTimePopUpTextMargin: (small: 1rem 0 2rem 0);
$addToFavoriteFirstTimePopUpTextFontWeight: 500;
$addToFavoriteFirstTimePopUpTextDotDisplay: none;
$addToFavoriteFirstTimePopUpLinkTextFontWeight: normal;
$addToFavoriteFirstTimePopUpLinkColor: (small: $brandPrimaryBlue, medium: $brandPrimaryBlue);
$addToFavoriteFirstTimePopUpLinkTextAfterContent: '\e90a';
$addToFavoriteFirstTimePopUpLinkTextAfterFontFamily: 'UnitedIcons';
$addToFavoriteFirstTimePopUpLinkTextAfterFontSize: 1rem;
$addToFavoriteFirstTimePopUpLinkTextFontSize: 1.4rem;
$addToFavoriteFirstTimePopUpButtonWidth: 100%;
$addToFavoriteFirstTimePopUpButtonMaxWidth: 32rem;

/* Product Details */
$productDetailsGoBackLinkContent: '\e90f';
$productDetailsGoBackLinkFontFamily: 'UnitedIcons';

$instoreHiwHowToBrowseIcon: url('~org/assets/images/instore/hiwBrowse.svg');
$instoreHiwHowToLinkIcon: url('~org/assets/images/instore/hiwLink.svg');
$instoreHiwHowToShopIcon: url('~org/assets/images/instore/hiwShop.svg');

$instoreLinkedImageIcon: url('~org/assets/images/instore/check.svg');
$instoreLinkedExpiringImageIcon: url('~org/assets/images/instore/expiring.svg');
$instoreCardLinkLinkedExpiringImageIcon: url('~org/assets/images/instore/expiring.svg');

$instoreHiwRelinkOffer: url('~org/assets/images/instore/relinkOffer.svg');
$instoreHiwMultiPurchase: url('~org/assets/images/instore/multiPurchase.svg');

$instoreHiwCredit: url('~org/assets/images/instore/credit.svg');

$instoreHiwAddCard: url('~org/assets/images/instore/addCard.svg');
$instoreHiwLinkCard: url('~org/assets/images/instore/linkCard.svg');
$instoreHiwAddMobile: url('~org/assets/images/instore/addMobile.svg');
$instoreHiwInStoreLinked: url('~org/assets/images/instore/inStoreLinked.svg');
$instoreHiwMultiLink: url('~org/assets/images/instore/multiLink.svg');

$instoreGetDirectionsContentAfter: ' \e917';
$instoreGetDirectionsContentFontFamily: 'UnitedIcons';

$instoreLinkCardLinkMoreCardsAfterContent: ' \e90a';
$instoreLinkCardLinkMoreCardsAfterFontFamily: 'UnitedIcons';

$instoreAddCardButtonContentBefore: '\e90e';
$instoreAddCardButtonContentAfter: '\e911';
$instoreAddCardButtonContentFontFamily: 'UnitedIcons';

$paymentCardListManageOpenAfterContent: '\e909';
$paymentCardListDeleteSuccessInfoIconSuccess: '\e904';
$paymentCardListDeleteConfirmationIconContent: '\e910';
$paymentCardListIconFontFamily: 'UnitedIcons';
$paymentCardListManageRemoveCardIcon: '\e90b';

$smsNumberModalButtonContent: '\e90a';
$smsNumberModalButtonContentFontFamily: 'UnitedIcons';
$instoreOfferModalStateOfferContent: '\e910';
$instoreOfferModalIconFontFamily: 'UnitedIcons';
$smsOptInSkipFontFamily: 'UnitedIcons';

$sweepTopStoresContent: '\e90a';
$sweepTopStoresFontFamily: 'UnitedIcons';

$instoreLinkErrorIconFontFamily: UnitedIcons, serif;

// Favorite Stores
$favoriteStoresMerchantTileHeight: (small: 19rem, medium: 18.3rem);
$favoriteStoresMerchantTileRebateNewValueFontSize: (small: 1.4rem, medium: 1.6rem);
$favoriteStoresManageFavoritesTileTextTextAlign: (small: center, medium: left);
$favoriteStoresManageFavoritesTileTextMargin: (small: 0, medium: 0);
$favoriteStoresManageFavoritesTileTextPadding: (small: 0 1rem, medium: 0 3.5rem 0 0);
$favoriteStoresManageFavoritesTileWrapPadding: (small: .3rem, medium: 0);
$favoriteStoresManageFavoritesTileWrapBorderRadius: .6rem;
$favoriteStoresManageFavoritesTileWrapBackground: #024;
$favoriteStoresManageFavoritesTileBorder: (small: 2px solid rgba(182, 184, 220, .25), medium: none);
$favoriteStoresManageFavoritesTileWrapHeight: (small: 19rem, medium: 18.3rem);
$favoriteStoresManageFavoritesTileHeight: (small: 100%, medium: 18.3rem);
$favoriteStoresMerchantTileItemBorder: none;
$favoriteStoresMerchantTileBoxShadow: (small: none, medium: (inset 0 0 0 2px $brandWhite, inset 0 0 0 4px $brandOuterBorderColor));
$favoriteStoresMerchantTileRebateHeight: (small: (), medium: 6.4rem);
$favoriteStoresMerchantTileRebateWidth: (small: (), medium: calc(100% - 2px));
$favoriteStoresMerchantTileRebatePositionLeft: (small: (), medium: 1px);
$favoriteStoresMerchantTileRebatePositionBottom: (small: (), medium: 1px);
$favoriteStoresManageFavoritesTileArrowContent: (small: (), medium: '\e90a');
$favoriteStoresManageFavoritesTileArrowFontFamily: (small: (), medium: 'UnitedIcons');
$favoriteStoresManageFavoritesTileArrowPadding: (small: (), medium: 0 0 0 1rem);

/* Contact Us : Dashboard List */
$dashboardListItemArrowIconColor: #6244bb;
$dashboardListItemArrowIconBeforeContent: '\e90a';
$dashboardListItemArrowIconBeforeFontFamily: 'UnitedIcons', serif;
$dashboardListItemImageOrderInquiryBackgroundImage: url('~org/assets/images/contact/Miles.svg');
$dashboardListItemImageMyAccountBackgroundImage: url('~org/assets/images/contact/Act.svg');
$dashboardListItemImageHowItWorksBackgroundImage: url('~org/assets/images/contact/HIW.svg');
$dashboardListItemImageReportAProblemBackgroundImage: url('~org/assets/images/contact/Report.svg');
$dashboardListItemImageOtherInquiryBackgroundImage: url('~org/assets/images/contact/Other.svg');
$dashboardListItemImageDataPrivacyBackgroundImage: url('~org/assets/images/contact/Data.svg');
$dashboardListItemImageBonusPostingDetailsBackgroundImage: url('~org/assets/images/contact/Bonus.svg');

/* Contact Us : Dashboard Inquiry */
$dashboardInquirySectionContinueInfoButtonColor: #006080;
$dashboardInquiryFormInputDatePickerPlaceholderFontFamily: 'Fontello', sans-serif;
$dashboardInquiryFormSelectBackground: #fff url('~org/assets/images/chevron_up.svg') no-repeat 95% 55%;
$dashboardInquiryContinueInfoButtonColor: #006080;
$dashboardAttachmentErrorColor: #f00;

/* Contact Us: Order Inquiry Pop Up */
$orderInquiryPopUpModalCloseDisplay: none;

/* Contact Us: Order Inquiry Progress Bar */
$orderInquiryProgressDesktopLineHeight: 2px;
$orderInquiryProgressDesktopLeftLineColor: #7c878e;
$orderInquiryProgressDesktopRightLineColor: #572c5f;

$orderInquiryProgressDesktopStepWrapBgColor: #e9e9e7;
$orderInquiryProgressDesktopStepLabelIncompleteAndActiveColor: #572c5f;
$orderInquiryProgressDesktopStepIconBorder: 2px solid #572c5f;
$orderInquiryProgressDesktopStepIconColor: #572c5f;
$orderInquiryProgressDesktopStepIconFontWeight: 500;
$orderInquiryProgressDesktopStepIconCompleteAndActiveColor: #572c5f;
$orderInquiryProgressDesktopStepIconCompleteAndActiveBackgroundColor: #572c5f;
$orderInquiryProgressDesktopStepIconCompleteAndActiveBorderColor: #572c5f;
$orderInquiryProgressDesktopStepIconCompleteContent: '\e908';
$orderInquiryProgressDesktopStepIconCompleteFontFamily: 'UnitedIcons';
$orderInquiryProgressDesktopStepIconCompleteTickFontSize: 1rem;
$orderInquiryProgressDesktopStepIconInCompleteAndActiveBorderColor: #7c878e;
$orderInquiryProgressDesktopStepIconInCompleteAndActiveColor: #7c878e;
$orderInquiryProgressDesktopStepIconCompleteBgColor: #572c5f;
$orderInquiryProgressDesktopStepIconCompleteBorderColor: #572c5f;
$orderInquiryProgressDesktopStepLabelColor: $brandPrimaryColor;
$orderInquiryProgressDesktopStepLabelIncompleteAndInactiveColor: #000;
$orderInquiryProgressDesktopStepLabelCompleteColor: $brandBlack;

$orderInquiryProgressMobileStepLabelFontWeight: normal;
$orderInquiryProgressMobileStepIconFontWeight: normal;
$orderInquiryProgressMobileStepsBgColor: $orderInquiryProgressDesktopStepWrapBgColor;
$orderInquiryProgressMobileStepIconCompleteBgColor: $orderInquiryProgressMobileStepsBgColor;
$orderInquiryProgressMobileStepIconIncompleteAndInactiveBgColor: $orderInquiryProgressMobileStepsBgColor;
$orderInquiryProgressMobileStepIconBgColor: #572c5f;
$orderInquiryProgressMobileStepIconCompleteTickColor: $brandPrimaryColor;
$orderInquiryProgressMobileStepIconCompleteAndActiveColor: $brandPrimaryColor;
$orderInquiryProgressMobileStepIconIncompleteColor: #999;
$orderInquiryProgressMobileStepLabelIncompleteStepActiveFontSize: 1.1rem;

/* Contact Us: Steps Review */
$orderInquiryStepsReviewSectionInfoWrapFontWeight: 500;
$orderInquiryStepsReviewSectionEditLinkFontWeight: 500;
$orderInquiryStepsReviewSectionEditLinkColor: #006080;

$allStoresMerchantItemInstoreImage: url('~org/assets/images/instore/in-store-quick-find.svg');
$sortJumpMerchantOptionsAlphaButtonSvg: url('~org/assets/images/chevron_open.svg') no-repeat no-repeat;
$sortJumpMerchantOptionsSelectDropDownSvg: url('~org/assets/images/chevron_down_1.svg') no-repeat 97% 52%;

$instoreOffers502x272MoreInfoIconContent: '\e905';
$instoreOffers502x272MoreInfoIconFontFamily: 'UnitedIcons';

/* Profile */
$profileInfoWrapBackground: -2rem url('~org/assets/images/palma_bg2.jpg') no-repeat;
$profileInfoParagraphColor: $brandTextColorInverse;
$profileInfoMainUserInfoHeaderColor: $brandTextColorInverse;
$profileInfoMainUserInfoHeaderFontFamily: 'OpenSans';
$profileInfoButtonColor: $brandTextColorInverse;
$profileInfoButtonBorder: $brandBorder;
$profileSelectBackground: #fff url('~org/assets/images/dropdown.png') no-repeat right center;

$profileSelectColor: #636363;
$profileSelectFontSize: 1.4rem;
$profileSelectLineHeight: 2.4rem;
$profileSelectBackground: $brandWhite url('~org/assets/images/dropdown.png') no-repeat right center;

/* Bonus Tracker */
$bonusTrackerBackground: #0b2340;
$bonusTrackerCompletedBackgroundImage: url('~org/assets/images/confetti-UA.png');
$bonusTrackerAfterContent: '\e909';
$bonusTrackerAfterFontFamily: 'UnitedIcons';
$bonusTrackerClosedAfterContent:'\e90b';
$bonusTrackerIndicatorSpendInfoColor: #68b3e7;
$bonusTrackerCountDownElementColor: #68b3e7;
$bonusTrackerCountDownElementBeforeColor: #68b3e7;
$bonusTrackerEarnedMessageFontWeight: 500;
$bonusTrackerCountdownMinWidth: 17.5rem;
$bonusTrackerIndicatorSpendInfoPositionBottom: (xsmall: -.5rem, medium: .5rem, large: -2rem);

/* Wistia video */
$wistiaVideoYoutubeVideoBackgroundColor: rgba(0, 0, 0, .8);
$wistiaVideoExitYoutubeContentBeforeColor: $brandNeutralLightColor2;
$wistiaVideoExitYoutubeContentBeforeFontSize: 4.5rem;
$wistiaVideoExitYoutubeContentBeforeLetterSpacing: -.45rem;
$wistiaVideoExitYoutubeContentBeforeFontFamily: 'Fontello';
$wistiaVideoExitYoutubeContentBeforeContent: '\f104';

/* AdBlock Warning */
$adBlockWarningIconColor: #ffc658;
$adBlockWarningIconFontSize: 2rem;
$adBlockWarningIconContent: '\e910';
$adBlockWarningIconFontFamily: 'UnitedIcons';
$adBlockWarningMessageBorderRadius: .4rem;
$adBlockWarningMessageBorder: .1rem solid $brandNeutralMediumColor;
$adBlockWarningMessageBorderLeft: .8rem solid #ffc658;
$adBlockWarningMessageInnerMargin: 0 auto 0 0;
$adBlockWarningMessageFontWeight: 500;
$adBlockWarningBackground: $brandBlack;
$adBlockWarningIconHeight: ();

/* Navigation banner */
$navBannerButtonBannerPadding: 0 0 0 40px;
$navBannerButtonBannerBackground: #fff url('~org/assets/images/hiw_button.png') no-repeat center;
$navBannerButtonBannerBackgroundSize: 120px;
$navBannerFavoritesBannerBackground: #fff url('~org/assets/images/hiw_favorites.png') no-repeat center;
$navBannerFavoritesBannerBackgroundSize: 120px;
$navBannerButtonBannerImagePositionTop: 50%;
$navBannerButtonBannerImageTransform: translateY(-50%);
$navBannerButtonBannerImageBoxShadow: -1px 2px 2px 1px rgba(0, 0, 0, .2);
$navBannerButtonBannerImageBorder: #dadbee solid 3px;
$navBannerButtonBannerImageBoxSizing: border-box;
$navBannerContentBackground: #fff;
$navBannerContentBorder: 3px solid #fff;
$navBannerContentBorderRadius: 4px;
$navBannerContentBoxShadow: inset 0 0 0 2px #e6e6e6;
$navBannerContentPadding: .8rem 2rem .8rem 9.5rem;
$navBannerContentFontSize: 1.2rem;
$navBannerContentColor: $brandTextColor;
$navBannerLinkWrapDisplay: block;
$navBannerLinkFontSize: 1.4rem;
$navBannerTitleMargin: 0 0 .5rem;
$navBannerTitleFontSize: 1.8rem;
$navBannerTextLineHeight: 2.4rem;
$navBannerTextMargin: 0 0 .2rem;

/* Email Success Message */
$emailSuccessMessagePadding: 17rem 4rem 4rem;
$emailSuccessMessageColor: #0c2340;
$emailSuccessMessageBackground: url('~org/assets/images/icons/email_popup.png') no-repeat 50% 60px/82px auto, #fff;
$emailSuccessMessageAfterDisplay: block;
$emailSuccessMessageAfterContent: '';
$emailSuccessMessageAfterPosition: absolute;
$emailSuccessMessageAfterPositionLeft: 0;
$emailSuccessMessageAfterPositionTop: 13rem;
$emailSuccessMessageAfterHeight: 2rem;
$emailSuccessMessageAfterWidth: 100%;
$emailSuccessMessageAfterBackground: -webkit-radial-gradient(50% 100%, 50% 20px, rgba(191, 191, 199, .5) 0%, rgba(255, 255, 255, 0) 100%);
$emailSuccessMessageH2FontSize: 2.4rem;
$emailSuccessMessageOlayDescFontWeight: 500;
$emailSuccessMessageCloseButtonWidth: 1.6rem;
$emailSuccessMessageCloseButtonBeforeColor: #162c48;
$emailSuccessMessageCloseButtonBeforeFontFamily: 'UnitedIcons';
$emailSuccessMessageCloseButtonBeforeContent: '\e90c';
$emailSuccessMessageCloseButtonBeforeFontSize: 1.6rem;
$emailSuccessMessageCloseButtonBeforeLineHeight: 2rem;
$emailSuccessMessageCloseButtonBeforeFontWeight: 100;
$emailSuccessMessageCloseButtonBeforeLetterSpacing: -.4rem;

/* Letterboxes */
$heroCarouselContainerPadding: (smallMobile: 0, mediumMobile: 0, smallTablet: 2rem,
largeMobileAndSmallDesktop: 2rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselBreakPointDotBorderColor: (smallTablet: (), largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselDisclaimerColor: (smallMobile: #666, mediumMobile: #666, smallTablet: #999,
largeMobileAndSmallDesktop: #999, largeTabletAndXLargeDesktop: #999);
$heroCarouselCouponCodeTextLetterSpacing: (smallMobile: .5px, mediumMobile: .5px, smallTablet: 1px,
largeMobileAndSmallDesktop: 1px, largeTabletAndXLargeDesktop: 1px);
$heroCarouselInfobarMarketingTextPadding: (smallMobile: 2rem 1rem 0, mediumMobile: 2rem 1rem 0, smallTablet: 0 1rem 0,
largeMobileAndSmallDesktop: 4rem 1rem 0, largeTabletAndXLargeDesktop: 4rem 1rem 0);
$heroCarouselInfobarMarketingHeaderMargin: (smallMobile: (), mediumMobile: (), smallTablet: 2rem 0 1rem,
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselInfobarMarketingCTAWidth: (smallMobile: 15.2rem, mediumMobile: 15.2rem, smallTablet: 15.2rem,
largeMobileAndSmallDesktop: 15.2rem, largeTabletAndXLargeDesktop: 15.2rem);
$heroCarouselArrowColor: #666;
$heroCarouselArrowFontSize: 1.6rem;
$heroCarouselMargin: (smallMobile: 0, mediumMobile: 0, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselBreakPointLogoPositionTop: (smallMobile: 7px, mediumMobile: 12px);
$heroCarouselBreakPointCouponCodeFontSize: (smallMobile: 1.2rem, mediumMobile: 1.4rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 1.3rem, largeTabletAndXLargeDesktop: 1.4rem);
$heroCarouselBreakPointCouponCodeLineHeight: (smallMobile: 1.8rem, mediumMobile: 1.8rem, smallTablet: 1.6rem,
largeMobileAndSmallDesktop: 1.4rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselCouponCodeTextFontSize: (smallMobile: 1rem, mediumMobile: 1rem, smallTablet: 1.2rem,
largeMobileAndSmallDesktop: 1.1rem, largeTabletAndXLargeDesktop: 1.2rem);
$heroCarouselBreakPointLongCopyFontSize: (smallMobile: 1.2rem, mediumMobile: 1.4rem, smallTablet: 1.7rem,
largeMobileAndSmallDesktop: 1.4rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselBreakPointLongCopyLineHeight: (smallMobile: 1.8rem, mediumMobile: 1.8rem, smallTablet: 2rem,
largeMobileAndSmallDesktop: 1.9rem, largeTabletAndXLargeDesktop: 3.2rem);
$heroCarouselBreakPointRebateFontSize: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 1.4rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselBreakPointRebateLineHeight: (smallMobile: 1.8rem, mediumMobile: 1.8rem, smallTablet: 2rem,
largeMobileAndSmallDesktop: 2.2rem, largeTabletAndXLargeDesktop: 2.7rem);
$heroCarouselInfobarMarketingHeaderFontSize: (smallMobile: 2.4rem, mediumMobile: 2.8rem, smallTablet: 2.8rem,
largeMobileAndSmallDesktop: 2.8rem, largeTabletAndXLargeDesktop: 2.4rem);
$heroCarouselInfobarMarketingTextFontSize: (smallMobile: 1.4rem, mediumMobile: 1.4rem, smallTablet: 1.8rem,
largeMobileAndSmallDesktop: 1.6rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselBreakPointOfferDetailsPadding: (smallMobile: 0 7px 7px 102px, mediumMobile: 0 10px 10px 102px, smallTablet: 0 0 0 16.85px,
largeMobileAndSmallDesktop: 0 0 0 18.86px, largeTabletAndXLargeDesktop: 0 2rem 0 1.5rem);
$heroCarouselBreakPointActiveDotBackground: (smallTablet: $brandPrimaryColor, largeMobileAndSmallDesktop: $brandPrimaryColor, largeTabletAndXLargeDesktop: $brandPrimaryColor);
$heroCarouselBreakPointDotBackground: (smallTablet: #ccc, largeMobileAndSmallDesktop: #ccc, largeTabletAndXLargeDesktop: #ccc);
$heroCarouselBreakPointDotWidth: (smallTablet: 1.2rem, largeMobileAndSmallDesktop: 1rem, largeTabletAndXLargeDesktop: 1.3rem);
$heroCarouselBreakPointDotHeight: (smallTablet: 1.2rem, largeMobileAndSmallDesktop: 1rem, largeTabletAndXLargeDesktop: 1.3rem);
$heroCarouselTileWithExtraPillImageBorder: (smallMobile: 1.64px solid #e6e6e6, mediumMobile: 1.64px solid #e6e6e6, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselInfobarMarketingCountdownCounterBorderRight: 1px solid rgba(212, 212, 208, .5);
$heroCarouselInfobarMarketingCountdownCounterBeforeMargin: 0 .5rem;
$heroCarouselOfferDetailsMinHeight: 10.5rem;
$heroCarouselOfferDetailsPositionBottom: .6rem;
$heroCarouselOfferDetailsPositionLeft: .6rem;
$heroCarouselOfferDetailsPositionRight: .6rem;
$heroCarouselTileWithExtraPillImagePaddingTop: calc(283 / 272 * 100%);
$heroCarouselBreakPointShortCopyFontSize: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 2.6rem,
largeMobileAndSmallDesktop: 2rem, largeTabletAndXLargeDesktop: 3.2rem);
$heroCarouselInfobarMarketingTextJustifyContent: (smallMobile: flex-start, mediumMobile: flex-start, smallTablet: center,
largeMobileAndSmallDesktop: center, largeTabletAndXLargeDesktop: center);
$heroCarouselInfobarMarketingTextFlexGrow: (smallMobile: unset, mediumMobile: unset, smallTablet: 1,
largeMobileAndSmallDesktop: 1, largeTabletAndXLargeDesktop: 1);
$heroCarouselInfobarMarketingPadding: (smallMobile: 3px, mediumMobile: 3px, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselInfobarMarketingContentBorder: (smallMobile: 2px solid #e6e6e6, mediumMobile: 2px solid #e6e6e6, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselContainerMargin: (smallMobile: 0 0 5rem 0, mediumMobile: 0 0 5rem 0, smallTablet: 0 0 5rem 0,
largeMobileAndSmallDesktop: 0 0 3rem 0, largeTabletAndXLargeDesktop: 0 0 3rem 0);
$heroCarouselSliderCounterColor: (smallMobile: $brandWhite, mediumMobile: $brandWhite,
smallTablet: (), largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselDotsPositionLeft: unset;
$heroCarouselDotsPositionBottom: (smallTablet: 1.5rem, largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselBreakPointOfferDetailsContentMinHeights: (smallTablet: 172px, largeMobileAndSmallDesktop: 152px, largeTabletAndXLargeDesktop: 186px);
$heroCarouselBreakPointRebatePrefixColor: (smallMobile: $rebateValueColor, mediumMobile: $rebateValueColor, smallTablet: $rebateValueColor,
largeMobileAndSmallDesktop: $rebateValueColor, largeTabletAndXLargeDesktop: $rebateValueColor);
$heroCarouselInfobarMarketingMargin: (smallMobile: 0, mediumMobile: 0, smallTablet: .5rem,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselInfobarMarketingCountdownPrefixTextFontWeight: (smallMobile: (), mediumMobile: (), smallTablet: 500,
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselInfobarMarketingCountdownCounterAfterFontWeight: (smallMobile: (), mediumMobile: (), smallTablet: 700,
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselExtraRewardsPillWidth: 9.3rem;
$heroCarouselBreakPointLogoPositionLeft: (smallMobile: -95px, mediumMobile: -95px);
$heroCarouselBreakPointRebateMarginBottom: (smallMobile: .5rem, mediumMobile: .5rem, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselButtonMarginTop: (smallMobile: 0, mediumMobile: 0, smallTablet: .8rem,
largeMobileAndSmallDesktop: .3rem, largeTabletAndXLargeDesktop: .2rem);
$heroCarouselContainerBoxShadow: (smallMobile: (), mediumMobile: (), smallTablet: (inset 0 0 0 3px #fff, inset 0 0 0 5px #e6e6e6),
largeMobileAndSmallDesktop: (inset 0 0 0 3px #fff, inset 0 0 0 5px #e6e6e6), largeTabletAndXLargeDesktop: (inset 0 0 0 3px #fff, inset 0 0 0 5px #e6e6e6));

/* Featured offers logo */
$featuredOffersLogoMeSlideMargin: 0 1rem;
$featuredOffersLogoItemTileWidth: 15.4rem;
$featuredOffersLogoItemTileHeight: 17.1rem;

// Sticky Cta Bar: HIW Page
$stickyCtaBarHiwContentFlexDirection: (small: column, medium: row);
$stickyCtaBarHiwWrapHeight: (small: (), medium: (11.2rem));
$stickyCtaBarHiwBackgroundColor: (small: $brandSecondaryColor);
$stickyCtaBarHiwColor: $brandWhite;
$stickyCtaBarHiwPadding: 2.6rem 0;
$stickyCtaBarHiwHeaderFontSize: 2rem;
$stickyCtaBarHiwHeaderLineHeight: 2.8rem;
$stickyCtaBarHiwHeaderFontWeight: 500;
$stickyCtaBarHiwHeaderColor: $brandWhite;
$stickyCtaBarHiwHeaderMargin: (small: 0 0 2rem, medium: 0);
$stickyCtaBarHiwHeaderPadding: (small: 0, medium: 1.6rem 2rem 1.6rem 0);
$stickyCtaBarHiwHeaderBorderRight: (small: (), medium: 1px solid rgba(212, 212, 208, .5));
$stickyCtaBarHiwHeaderTextAlign: center;
$stickyCtaBarHiwButtonFontSize: 1.6rem;
$stickyCtaBarHiwButtonWidth: (small: 20rem);
$stickyCtaBarHiwButtonPadding: (small: 1.5rem 6rem 1.4rem);
$stickyCtaBarHiwButtonMargin: (small: (), medium: 0 0 0 2.5rem);

// Sticky Cta Bar: VHP Page
$stickyCtaBarVhpContentFlexDirection: (small: column, medium: row);
$stickyCtaBarVhpWrapHeight: (small: (), medium: (), large: (), xlarge: 11rem);
$stickyCtaBarVhpBackgroundColor: (small: $brandSecondaryColor);
$stickyCtaBarVhpColor: $brandWhite;
$stickyCtaBarVhpPadding: 2.6rem 0;
$stickyCtaBarVhpHeaderFontSize: 2rem;
$stickyCtaBarVhpHeaderLineHeight: 2.8rem;
$stickyCtaBarVhpHeaderFontWeight: 500;
$stickyCtaBarVhpHeaderColor: $brandWhite;
$stickyCtaBarVhpHeaderMargin: (small: 0 0 2rem, medium: 0);
$stickyCtaBarVhpHeaderPadding: (small: 0, medium: 1.6rem 2rem 1.6rem 0);
$stickyCtaBarVhpHeaderBorderRight: (small: (), medium: 1px solid rgba(212, 212, 208, .5));
$stickyCtaBarVhpHeaderTextAlign: center;
$stickyCtaBarVhpButtonFontSize: 1.6rem;
$stickyCtaBarVhpButtonWidth: (small: 20rem);
$stickyCtaBarVhpButtonPadding: (small: 1.5rem 6rem 1.4rem);
$stickyCtaBarVhpButtonMargin: (small: (), medium: 0 0 0 2.5rem);

/* Search results */
$searchFacetsBorder: #ccc solid 1px;
$searchFacetsBorderRadius: 4px;
$searchFacetsHeadColor: $brandWhite;
$searchFacetsHeadBackground: $brandSecondaryColor;
$searchFacetsHeadPadding: 1.2rem 2.5rem;
$searchFacetsHeadFontSize: 2rem;
$searchFacetsHeadFontWeight: 500;
$searchFacetsContentColor: ();
$searchFacetsContentBackground: transparent;
$searchFacetsContentPadding: 1.5rem 0;
$searchFacetsContentMargin: -3px 0 0;
$searchFacetsContentFontSize: 1.4rem;
$searchFacetsContentLineHeight: 2.2rem;
$searchFacetsContentFontWeight: 500;
$searchFacetsContentListStyle: none;
$searchFacetsContentItemMarginBottom: 0;
$searchFacetsLinkPadding: .5rem 2.5rem;
$searchFacetsMargin: 0 -.5rem 1.5rem;

/* EmailOptIn MHP */
$emailOptInImageBackground: url('~org/assets/images/email_optin.png') no-repeat 50% 0/contain;
$emailOptInHeadingFontSize: 3.2rem;
$emailOptInHeadingFontWeight: 500;
$emailOptInHeadingLineHeight: 4rem;

/* Header navigation */
$navigationListItemLetterSpacing: 1.2px;
$navigationListItemBeforeContent: '';
$navigationListItemBeforeBackground: linear-gradient(90deg, rgba(212, 212, 208, 0) 0%, rgba(212, 212, 208, .5) 17.53%, rgba(212, 212, 208, .5) 52.37%, rgba(212, 212, 208, .5) 80.86%, rgba(212, 212, 208, 0) 99.97%);
$navigationListItemBeforePosition: absolute;
$navigationListItemBeforeHeight: 1px;
$navigationListItemBeforeWidth: calc(100% + 4rem);
$navigationListItemBeforeTop: 0;
$navigationListItemBeforeLeft: -2rem;
$navigationListItemLinkTextTransform: uppercase;
$navigationListItemFontWeight: bold;
$navigationListItemLinkColor: $brandWhite;
$navigationListItemLinkMargin: 0 -2rem;
$navigationListItemLinkPadding: 2.2rem 3.5rem;
$navigationListItemHoverTextDecoration: underline;
$navigationListDropdownItemTriggerColor: $brandWhite;
$navigationListDropdownItemTriggerTextTransform: uppercase;
$navigationListDropdownItemTriggerBeforeContent: '\e90a';
$navigationListDropdownItemTriggerBeforeFontFamily: 'UnitedIcons', serif;
$navigationListDropdownItemPaddingLeft: 3.5rem;
$navigationListDropdownItemTriggerMargin: 0 -2rem;
$navigationListDropdownItemMenuTextTransform: none;
$navigationListDropdownItemMenuFontWeight: 500;
$navigationListDropdownItemMenuLetterSpacing: .29px;
$navigationListDropdownItemMenuBackground: rgba(182, 184, 220, .25);
$navigationListDropdownItemMenuLinkColor: $brandWhite;

$categoryFlyoutTitleFontSize: 2rem;
$categoryFlyoutTitleColor: $brandWhite;
$categoryFlyoutTitleFontWeight: 500;
$categoryFlyoutTitleMargin: 0 0 1.5rem;
$categoryFlyoutTitleRebateOldValueFontSize: 1.4rem;
$categoryFlyoutTitleRebateNewValueFontSize: 1.4rem;
$categoryFlyoutTitleRebateNewValueColor: #6244bb;
$categoryFlyoutTitleRebateNewValueFontWeight: 500;

$navCategoryFeaturedItemTitleFont: 500 1.2rem 'OpenSans', Helvetica, Arial, sans-serif;
$navCategoryFeaturedItemTitleTextTransform: uppercase;
$navCategoryFeaturedItemTitleLetterSpacing: 1.3px;
$navCategoryFeaturedItemElevationNewValueFontWeight: 500;
$navCategoryFeaturedItemRebateFontSize: 1.4rem;
$navCategoryFeaturedItemRebateColor: #fff;
$navCategoryFeaturedItemRebateFontWeight: 500;
$navCategoryFeaturedItemRebateOldValueFontSize: 1.2rem;
$navCategoryFeaturedItemRebateOldValueColor: #ccc;
$navCategoryFeaturedItemHoverColor: #fff;
$navCategoryFeaturedItemHoverTextDecoration: underline;

/* Trending and Seasonal Offers */
$trendingAndSeasonalOfferBackground: #fff;
$trendingAndSeasonalOfferBorder: .5px solid #ccc;
$trendingAndSeasonalOfferBorderRadius: 4px;
$trendingAndSeasonalOfferBoxShadow: 0 0 2px 0 rgba(0, 0, 0, .2);
$trendingAndSeasonalOffersBackground: (small: rgba(182, 184, 220, .25), medium: none);

/* Flip Tile */
$flipTileFavoriteIconMargin: 0;
$flipTileFavoriteIconWidth: (small: 2.1rem, medium: 2.1rem);
$flipTileItemBorderBottom: 1px solid #d4d4d0;
$flipTileItemPadding: 0 1.5rem;
$flipTilePremierRebateTieredPrefixFirstLetterTextTransform: none;
$flipTileLogoWrapPadding: .2rem;
$flipTileLogoWrapBorderRadius: .2rem;

/* Infobar Accordion */
$infoBarAccordionOfferBackground: rgba(105, 179, 231, .25);
$infoBarAccordionSecondOfferBackground: #000;
$infoBarAccordionOfferCTATextAfterContent: '\e90a';
$infoBarAccordionOfferCTATextAfterFontFamily: 'UnitedIcons', sans-serif;
$infoBarAccordionOfferCTATextAfterColor: #fff;
$infoBarAccordionOfferCTATextBoxShadow: inset 0 0 44px 0 rgba(0, 0, 0, .2);
$infoBarAccordionOfferCTATextFontSize: 1.5rem;
$infoBarAccordionOfferCTATextFontWeight: 500;
$infoBarAccordionExpiredDotDisplay: none;
$infoBarAccordionOfferCTATextAfterBottom: 49%;
$infoBarAccordionOfferRebateFontWeight: 500;
$infoBarAccordionCollapsedOfferRebateFontSize: (small: 1.2rem, medium: 1.4rem);
$infoBarAccordionCollapsedOfferRebateValueFontSize: (small: 1.2rem, medium: 1.4rem);
$infoBarAccordionOfferBorderRadius: 0 0 0 4px;
$infoBarAccordionSecondOfferBorderRadius: 0 4px 4px 0;

$infoBarAccordionExpandIconBeforeContent: '\e911';
$infoBarAccordionExpandIconBeforeFontFamily: 'UnitedIcons', sans-serif;
$infoBarAccordionExpandIconBeforeColor: #000;
$infoBarAccordionExpandIconBeforeFontSize: 1.1rem;
$infoBarAccordionExpandIconBeforeBackground: #b6b8dc;
$infoBarAccordionOfferRebateOldValueColor: #b6b8dc;
$infoBarAccordionOfferRebateOldValueAfterContent: ',';

/* Infobar Marketing */
$infoBarMarketingContentBorder: 2px solid #e6e6e6;
$infoBarMarketingTileBorderRadius: 4px;
$infoBarMarketingTilePadding: 3px;
$infoBarMarketingCountdownWrapBackground: #024;
$infoBarMarketingCountdownWrapBorderRadius: 3px 3px 0 0;
$infoBarMarketingCountdownWrapTextAlign: left;
$infoBarMarketingCountdownDigitBeforeColor: #fff;
$infoBarMarketingHeaderLetterSpacing: -.56px;
$infoBarMarketingCountdownClockPrefixTextTransform: uppercase;
$infoBarMarketingCountdownWrapPadding: (small: .8rem .5rem, xlarge: 1.4rem .5rem);
$infoBarMarketingCountdownWrapMargin: (small: (), large: -.5rem 0 0, xlarge: -.5rem 0 0);
$infoBarMarketingHeaderFontSize: (small: 2rem, xlarge: 2.4rem);
$infoBarMarketingHeaderLineHeight: (small: 2.4rem, xlarge: 3.4rem);
$infoBarMarketingButtonMargin: (small: auto auto 1rem 0, medium: auto auto 2rem 0);
$infoBarMarketingCountdownDigitBeforeBorderRight: 1px solid rgba(212, 212, 208, .5);
$infoBarMarketingCountdownDigitBeforeMargin: 0 .6rem;

/* Featured Deals */
$featuredDealsButtonDisplay: block;
$featuredDealsButtonMaxWidth: 100%;
$featuredDealsButtonMargin: 4rem auto 0;
$featuredDealsButtonBackground: none;
$featuredDealsButtonBorder: 2px solid $brandPrimaryColor;
$featuredDealsButtonPadding: 1.44rem 3rem;
// Rounded View
$featuredDealMobileRoundedBorderBottom: 1px solid rgba(212, 212, 208, .5);
$featuredDealMobileRoundedMerchantNameColor: $brandBlack;
$featuredDealMobileRoundedMerchantNameFontSize: 2rem;
$featuredDealMobileRoundedMerchantNameLineHeight: 3.2rem;
$featuredDealMobileRoundedMerchantNameFontWeight: 500;
$featuredDealMobileRoundedFavoriteIconWidth: 2rem;
$featuredDealMobileRoundedFavoriteIconHeight: 2rem;
$featuredDealMobileRoundedRebateOldValueAfterContent: '';
$featuredDealMobileRoundedCouponCodeLabelFontSize: 1.2rem;
$featuredDealMobileRoundedCouponCodeFontSize: 1rem;
$featuredDealMobileRoundedRebatePrefixFontWeight: 500;
$featuredDealMobileRoundedRebatePrefixColor: $rebateValueColor;
// Desktop
$featuredDealDesktopOverlayLinkPadding: 2.5rem 1.5rem 7rem 1.5rem;
$featuredDealDesktopRebateHeight: 6.3rem;
$featuredDealDesktopRebateBackground: #f7f7f7;
$featuredDealDesktopRebateMargin: 0 .4rem .4rem .4rem;
$featuredDealDesktopRebateOldValueFontSize: 1.2rem;
$featuredDealDesktopPillWidth: calc(100% - 8px);
$featuredDealDesktopRebatePadding: 0;
$featuredDealDesktopOfferItemContentTextAlign: left;
$featuredDealDesktopOfferDetailsLineHeight: 2.4rem;
$featuredDealDesktopCouponCodeFontSize: 1rem;
$featuredDealDesktopCouponCodeLineHeight: 1.8rem;
$featuredDealDesktopExpireFontSize: 1.2rem;
$featuredDealDesktopExpireColor: $brandNeutralDarkColor3;
$featuredDealDesktopWidth: 23.8rem;
$featuredDealDesktopMinHeight: 35.5rem;
$featuredDealDesktopOfferDetailsMinHeight: 7.3rem;
$featuredDealDesktopBorder: 1px solid $brandNeutralMediumColor;
$featuredDealDesktopBorderRadius: .4rem;
$featuredDealDesktopBoxShadow: .5px .5px 2px 0 rgba(0, 0, 0, .2), inset 0 0 0 2px $brandWhite, inset 0 0 0 4px $brandGray;
$featuredDealDesktopFavoriteIconWidth: 2.1rem;
$featuredDealDesktopFavoriteIconHeight: 2.1rem;
$featuredDealDesktopFavoriteIconWrapPositionRight: .5rem;
$featuredDealDesktopFavoriteIconWrapPositionTop: .5rem;
$featuredDealDesktopRebateTierNameColor: $rebateValueColor;
$featuredDealDesktopPillBottom: 6.8rem;

/* Header Extra Rewards */
$headerExtraRewardsPadding: 1.2rem 0;
$headerExtraRewardsSectionTitleMargin: 0 0 1rem;
$headerExtraRewardsHeaderFontWeight: bold;
$headerExtraRewardsHeaderMargin: .5rem 1.5rem 1.2rem;
$headerExtraRewardsHeaderTextTransform: uppercase;
$headerExtraRewardsHeaderLetterSpacing: 1.05px;
$headerExtraRewardsHeaderPaddingBottom: 0;
$headerExtraRewardsSectionTitleBorderBottom: 1px solid #d4d4d0;

/* Popular Offers */
$popularOffersBackground: (small: rgba(212, 212, 208, .25), medium: none);

/* Top Stores */
// Expandable
$topStoresExpandableBackground: linear-gradient(180deg, $brandSecondaryColor 0%, #0033a0 100%);
$topStoresExpandableTextColor: $brandWhite;
$topStoresExpandableMerchantTileMerchantRebateV2Background: transparent;
$topStoresExpandableMerchantTileMerchantRebateV2PositionTop: 15.5rem;
$topStoresExpandableMerchantTileMerchantRebateV2ElevationNewFontSize: 1.4rem;
$topStoresExpandableMerchantTileBorder: 2px solid $brandOuterBorderColor;
$topStoresExpandableMerchantTilePositionTop: 0;
$topStoresExpandableMerchantTilePositionBottom: 0;
$topStoresExpandableMerchantTilePositionLeft: 0;
$topStoresExpandableMerchantTilePositionRight: 0;
$topStoresExpandableMerchantFavoriteIconPositionTop: 2rem;
$topStoresExpandableButtonBorder: 2px solid $brandPrimaryColor;
$topStoresExpandableButtonPadding: 1.44rem 3rem;
// Desktop
$topStoresMerchantTileWidth: 12.1rem;
$topStoresMerchantTileHeight: 15.5rem;
$topStoresMerchantTileFavoriteIconPositionRight: -.2rem;
$topStoresMerchantTileFavoriteIconHeight: 1.9rem;
$topStoresMerchantTileFavoriteIconWidth: 1.9rem;
$topStoresMerchantTilePillPositionBottom: 6rem;

/* Trending Stores */
$trendingStoresNewMemberPadding: 4rem 0 5rem 0;
$trendingStoresNewMemberH2TextAlign: center;
$merchantWithOfferCountH3FontWeight: normal;
$trendingStoresDividerBackground: rgba(212, 212, 208, .5);
$trendingStoresH2TextAlign: (small: left, medium: left);
$trendingStoresMerchantLinkTileFavoriteIconPositionTop: (small: (), medium: 0);
$trendingStoresMerchantLinkTileFavoriteIconPositionRight: (small: (), medium: 0);
$trendingStoresMerchantLinkTileRebatePositionTop: (small: (), medium: 10.7rem);
$trendingStoresMerchantLinkTileRebatePadding: (small: .7rem 0 0 0);

/* Trending Stores Inverted */
$trendingStoresInvertedBackground: $brandSecondaryColor;
$trendingStoresInvertedAlignItems: center;
$trendingStoresInvertedColor: $brandWhite;

/* VHP Trending Stores */
$trendingStoresVhpMerchantLinkTileWidth: (small: 15.4rem, medium: (), large: 100%);
$trendingStoresVhpMerchantLinkTileHeight: (small: 18.4rem, medium: (), large: 100%);
$trendingStoresVhpMerchantLinkTilePillTop: (small: 55%, medium: (), large: ());
$trendingStoresVhpMerchantLinkTileRebatePositionTop: (small: 10.7rem, medium: (), large: ());
$trendingStoresVhpMerchantLinkTileOffersCountDisplay: (small: none, medium: (), large: ());

/* New member Trending Stores */
$trendingStoresNewMemberBackground: #0033a0;
$trendingStoresNewMemberH2Color: $brandWhite;

/* Marketing Merchants Home (Shopping Essentials) */
$marketingMerchantsHomeTileWidth: (small: 24rem, medium: 12rem);
$marketingMerchantsHomeTileHeight: (small: 23.7rem, medium: 15.4rem);
$marketingMerchantsHomeViewAllLinkInsideSliderBackground: #0b2340;
$marketingMerchantsHomeViewAllLinkInsideSliderColor: $brandWhite;
$marketingMerchantsHomeViewAllLinkInsideSliderFontSize: 1.8rem;
$marketingMerchantsHomeViewAllLinkInsideSliderPadding: 3px;
$marketingMerchantsHomeViewAllLinkInsideSliderLinkBackground: #0b2340;
$marketingMerchantsHomeViewAllLinkInsideSliderLinkBorderRadius: 6px;
$marketingMerchantsHomeViewAllLinkInsideSliderBorderRadius: 1px;
$marketingMerchantsHomeViewAllLinkInsideBorder: 2px solid rgba(182, 184, 220, .25);
$marketingMerchantsHomeViewAllLinkInsideBorderRadius: 1px;

/* Pick Favorites */
$pickFavoritesHeadingTextAlign: left;
$pickFavoritesMerchantLogoTileFavoriteIconWidth: 1.7rem;
$pickFavoritesMerchantLogoTileFavoriteIconHeight: 1.6rem;
$pickFavoritesMerchantLogoTileFavoriteIconTop: .4rem;
$pickFavoritesMerchantLogoTileFavoriteIconRight: .2rem;
$pickFavoritesButtonMargin: 4rem 0 0;
$pickFavoritesButtonBorder: 2px solid $brandPrimaryColor;
$pickFavoritesButtonPadding: 1.44rem 3rem;

/* Sticky Footer */
// Mobile
$stickyFooterMobileBackground: $brandSecondaryColor2;
$stickyFooterMobileContentBorderTop: none;
$stickyFooterMobileContentBorderBottom: 1px solid rgba(212, 212, 208, .5);
$stickyFooterMobileContentPadding: 0 0 6.2rem;
$stickyFooterMobileMerchantInfoPadding: 0 0 0 2.6rem;
$stickyFooterMobileMerchantNameMargin: -.2rem 0 1rem 0;
$stickyFooterMobileUseCodeFontSize: 1.2rem;
$stickyFooterMobileCodeLetterSpacing: .75px;
$stickyFooterMobileShopNowFontSize: 1.2rem;
$stickyFooterMobileShopNowFontWeight: bold;
$stickyFooterMobileShopNowAfterContent: '\e90a';
$stickyFooterMobileShopNowAfterFontFamily: 'UnitedIcons';
$stickyFooterMobileShopNowAfterMargin: 0 0 0 1rem;
$stickyFooterMobileShopNowAfterFontWeight: normal;
$stickyFooterMobileZIndex: 2;
$stickyFooterMobileMerchantRebateFontWeight: 500;
// Desktop
$stickyFooterDesktopWrapBackground: #e9e9e7;
$stickyFooterDesktopBackground: #131b28;
$stickyFooterDesktopMerchantRebateColor: $brandWhite;
$stickyFooterDesktopMerchantRebatePrefixColor: $brandWhite;
$stickyFooterDesktopButtonFontSize: (medium: 1.4rem, xlarge: 1.4rem);
$stickyFooterDesktopButtonPadding: 1.3rem 2rem;
$stickyFooterDesktopControlBorderRadius: 4px 4px 0 0;
$stickyFooterDesktopControlWidth: 5.6rem;
$stickyFooterDesktopControlHeight: 4.5rem;
$stickyFooterDesktopControlBackground: #131b28;
$stickyFooterDesktopControlBoxShadow: .5px .5px 2px 0 rgba(0, 0, 0, .2);
$stickyFooterDesktopControlBeforeContent: '\e909';
$stickyFooterDesktopControlBeforeFontFamily: 'UnitedIcons';
$stickyFooterDesktopControlBeforeCollapsedContent: '\e90b';
$stickyFooterDesktopControlCollapsedColor: $brandPrimaryColor;
$stickyFooterDesktopControlCollapsedBorder: 2px solid $brandPrimaryColor;
$stickyFooterDesktopMerchantRebateOldValueColor: $brandNeutralMediumColor;
$stickyFooterDesktopMerchantRebateTierNameColor: $brandWhite;
$stickyFooterDesktopButtonWrapMargin: 0 3rem 0 0;
$stickyFooterDesktopContentJustifyContent: space-evenly;
$stickyFooterDesktopMerchantInfoMargin: 0 1rem;
$stickyFooterDesktopMerchantLogoWrapMargin: (medium: 0 9rem 0 0, large: 0 9rem 0 0);
$stickyFooterDesktopImagePositionBottom: 1rem;
$stickyFooterDesktopCodeLetterSpacing: .5px;

/* Explore Categories */
$exploreCategoriesSectionPadding: (small: 5rem 0 0);
$exploreCategoriesTitleDotDisplay: none;
$exploreCategoriesSectionTitleH2TextAlign: (small: left);
$exploreCategoriesSectionTitleH2FontWeight: (small: 500);
$exploreCategoriesItemLinkPadding: 0 1rem 0 0;
$exploreCategoriesListPadding: 0 0 6rem;
$exploreCategoriesListBorderBottom: 1px solid rgba(212, 212, 208, .5);
$exploreCategoriesItemLinkBorder: 2px solid #7456c6;
$exploreCategoriesItemLinkBackground: $brandPrimaryColor;
$exploreCategoriesIconBeforeFontFamily: 'ua_categories';
$exploreCategoriesIconBeforeColor: $brandSecondaryColor2;
$exploreCategoriesIconBeforeDepartmentStoresContent: '\e805';
$exploreCategoriesIconBeforeComputersElectronicContent: '\e804';
$exploreCategoriesIconBeforeWomensClothingContent: '\e81e';
$exploreCategoriesIconBeforeMensClothingContent: '\e814';
$exploreCategoriesIconBeforeBeautyScincareContent: '\e801';
$exploreCategoriesIconBeforeShoesContent: '\e817';
$exploreCategoriesIconBeforeSportsOutdoorsContent: '\e81a';
$exploreCategoriesIconBeforeFlowersGiftsContent: '\e809';
$exploreCategoriesIconBeforeBabiesKidsToysContent: '\e802';
$exploreCategoriesIconBeforeHomeKitchenContent: '\e810';
$exploreCategoriesIconBeforeHealthWellnessContent: '\e80f';
$exploreCategoriesIconBeforeToolsAutoPetsContent: '\e81b';
$exploreCategoriesIconBeforePositionTop: 27%;
$exploreCategoriesIconBeforeDepartmentStoresPositionTop: 29%;
$exploreCategoriesIconBeforeDepartmentStoresPositionLeft: 14%;

/* Earning Snapshot */
$earningsSnapshotSectionBackground: (small: #024 linear-gradient(180deg, rgba(125, 129, 195, 0) 0%, rgba(125, 129, 195, .1) 8.08%, rgba(40, 41, 62, .4) 51.83%, #000 100%));
$earningsSnapshotSummaryFontWeight: 400;
$earningsSnapshotTransactionMerchantColor: $brandPrimaryBlue;
$earningsSnapshotTransactionMerchantFontSize: 1.4rem;
$earningsSnapshotTransactionMerchantFontWeight: bold;
$earningsSnapshotTransactionInfoFontSize: 1.4rem;
$earningsSnapshotTransactionInfoTopFontWeight: 400;
$earningsSnapshotTransactionInfoTopLineHeight: 2.4rem;
$earningsSnapshotTransactionInfoBottomFontWeight: 400;
$earningsSnapshotTransactionInfoBottomLineHeight: 2.4rem;
$earningsSnapshotTransactionInfoBottomRewardFontWeight: 700;
$earningsSnapshotButtonPadding: 1.44rem 3rem;

/* EmailOptIn MHP */
$emailOptInWrapperSectionPadding: (small: 4rem 1.5rem 6rem);
$emailOptInHeadingFontWeight: 500;
$emailOptInWrapperHeight: 55rem;
$emailOptInTextLineHeight: 2.4rem;
$emailOptInSwitcherLabelTextFontSize: 1.6rem;
$emailOptInSwitcherLabelTextLineHeight: 2.4rem;
$emailOptInSwitcherWrapperPositionBottom: 4.5rem;
$emailOptInSwitcherWrapperWidth: 280px;
$emailOptInHeadingDotDisplay: none;
$emailOptInWrapperOptedInTextMargin: 5rem 4rem 4rem;

/* Switcher */
$switcherCheckedBackground: $brandPrimaryColor;
$switcherBackground: rgba(120, 120, 128, .16);

/* Table */
$tableHeadTrThFontWeight: 500;
$tableHeadTrThButtonFontWeight: 500;

/* How it works */
$howItWorkSectionTitleH2TextAlign: (small: center, medium: ());
$howItWorksItemTitleFontWeight: (small: 400, medium: ());
$howItWorksItemMarginBottom: (small: 2rem);
$howItWorksInvertedDotsButtonWidth: 1rem;
$howItWorksInvertedDotsButtonHeight: 1rem;

$howItWorksInvertedSectionBackground: (small: #024);
$howItWorksInvertedSectionTitleH2Color: (small: #fff);
$howItWorksInvertedItemTitleColor: #fff;
$howItWorksInvertedIItemDescriptionColor: #fff;
$howItWorksCarouselDotWidth: 1rem;
$howItWorksCarouselDotHeight: 1rem;

/* Testimonials */
$testimonialsSectionTitleAlignItems: (small: flex-start);
$testimonialsSectionBackground: (small: url('~org/assets/images/palma_bg.jpg') no-repeat center 30% / cover);
$testimonialsStaticItemsTextBeforeBackgroundImage: url('~org/assets/images/icons/quotation_open.png');
$testimonialsStaticItemsTextAfterBackgroundImage: url('~org/assets/images/icons/quotation_close.png');
$testimonialsStaticTextPseudoContent: '';
$testimonialsStaticTextPseudoWidth: 2.5rem;
$testimonialsStaticTextPseudoHeight: 2.5rem ;
$testimonialsStaticTextPseudoPosition: absolute;
$testimonialsStaticTextPseudopositionTop: 30px;
$testimonialsSectionTitleH2FontSize: (small: 2.4rem);
$testimonialsSectionTitleH2LineHeight: (small: 3.2rem);
$testimonialsIconBeforeContent: '\e912';
$testimonialsIconBeforeFontFamily: 'UnitedIcons';
$testimonialsIconBeforeFontSize: 3.5rem;
$testimonialsIconHeight: 4rem;
$testimonialsIconWidth: 4rem;
$testimonialsStaticTextLineHeight: 2.4rem;
$testimonialsStaticTextFontSize: 1.4rem;
$testimonialsStaticItemsBackground: #fff;
$testimonialsStaticItemsBorderRadius: .6rem;
$testimonialsStaticItemsOpacity: .9;
$testimonialsStaticItemsPadding: 4rem 4rem 2rem 4rem;
$testimonialsStaticTextMaxWidth: 24rem;
$testimonialsCarouselItemsBackground: #fff;
$testimonialsCarouselItemsBorderRadius: .6rem;
$testimonialsCarouselItemsOpacity: .9;
$testimonialsCarouselItemsMargin: (small: (), medium: (), large: 0 10rem);
$testimonialsCarouselItemsPadding: 4rem 4rem 3rem 4rem;
$testimonialsCarouselTextBeforeContent: '"';
$testimonialsCarouselTextAfterContent: '"';
$testimonialsCarouselSectionTitleH2FontSize: (small: 3.2rem);
$testimonialsCarouselSectionTitleH2LineHeight: (small: 4rem);

/* Button Benefits Home Page */
$homeButtonBenefitsHeaderFontSize: 3.2rem;
$homeButtonBenefitsHeaderLineHeight: 4rem;
$homeButtonBenefitsListFontSize: 1.6rem;
$homeButtonBenefitsListLineHeight: 3.2rem;
$homeButtonBenefitsListColor: $brandNeutralDarkColor;

/* Credit Card Marketing Banner */
$creditCardBannerBackground: (small: #f7f7f7);
$creditCardBannerMargin: (small: 0, medium: 0 -1rem, large: 0 -3rem, xlarge: 0 auto);
$creditCardBannerButtonMinWidth: (medium: 15rem, large: 24rem, xlarge: 26rem);
$creditCardBannerTextMargin: (medium: 1rem auto);
$creditCardBannerButtonPadding: (medium: 1.44rem 1rem);
$creditCardBannerInsideWrapperJustifyContent: space-between;
$creditCardBannerInsideWrapperFlexGrow: 1;
$creditCardBannerBeforeBackground: #ededf6;
$creditCardBannerButtonBorder: 2px solid $brandPrimaryColor;
$creditCardBannerButtonAfterFontFamily: 'UnitedIcons', serif;
$creditCardBannerButtonAfterContent: '\e917';
$creditCardBannerWrapperBackground: #f7f7f7;

/* Button Overlay */
$buttonOverlayBorderRadius: .4rem;
$buttonOverlayContentBackground: url('~org/assets/images/button_overlay_background.jpg') no-repeat center;
$buttonOverlayBlockOnePositionTop: 33rem;
$buttonOverlayBlockTwoPositionTop: 65rem;
$buttonOverlayBlockThreePositionTop: 124rem;
$buttonOverlayBlockFourPositionTop: 219rem;
$buttonOverlayBlockPadding: 0 13.5rem;
$buttonOverlayBlockTwoPadding: 0 9rem;
$buttonOverlayBlockThreePadding: 0 7rem;
$buttonOverlayBlockFourPadding: 0 7.5rem;
$buttonOverlayMottoLetterSpacing: 1px;
$buttonOverlayMottoTextTransform: uppercase;
$buttonOverlayHiwHintsColor: $brandBlack;
$buttonOverlayHiwHintsBlockOneTextMarginTop: 2rem;
$buttonOverlayHiwHintsSubheaderMargin: 1rem 0;
$buttonOverlayHiwHintsMargin: 25rem 0 3rem 0;
$buttonOverlayHiwHintsBorder: 1px solid $brandWhite;
$buttonOverlayHiwHintsTextMargin: 0 0 1rem 0;
$buttonOverlayButtonBorder: 1px solid $brandViolet;
$buttonOverlayHeaderMargin: 1rem 0;
$buttonOverlayScrollbarTrackPieceBackground: rgba(212, 212, 208, .5);
$buttonOverlayScrollbarThumBackground: #7c878e;

/* Sweeps */
$sweepsMoreWaysToEarnBenefitItemIconBackgroundImage: url('~org/assets/images/icons/check_in.svg');
$sweepsMoreWaysToEarnBenefitItemOverflow: visible;
$sweepsTermsFullRulesLinkColor: $brandPrimaryBlue;
$sweepsTermsFullRulesLinkFontWeight: 500;
$sweepsTermsLinkFontWeight: 500;

/* Sponsored offers - Horizontal view */
$horizontalOfferBoxShadow: inset 0 0 0 2px $brandWhite, inset 0 0 0 4px $brandGray, .5px  .5px 2px 0 rgba(0, 0, 0, .2);
$horizontalOfferButtonWidth: 22.8rem;
$horizontalOfferButtonBackground: $brandWhite;
$horizontalOfferButtonColor: $brandPrimaryColor;
$horizontalOfferButtonBorder: 2px solid $brandPrimaryColor;
$horizontalOfferButtonHoverBackground: #f2f2f2;
$horizontalOfferButtonHoverBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .5);

/* Hybrid Search Modal */
$hybridSearchModalContentBackground: $brandSecondaryColor;
$hybridSearchModalCloseColor: $brandWhite;

/* Hybrid Quick Search */
$hybridQuickSearchBackground: (small: $brandSecondaryColor, medium: ());
$hybridQuickSearchWrapTitleFontWeight: 500;
$hybridQuickSearchNoResultsFontWeight: (small: 500, medium: ());
$hybridQuickSearchRebateFontWeight: 500;
$hybridQuickSearchInstoreRebateFontWeight: 500;

/* Hybrid Search Results Page */
$hybridProductsWrapBackground: $brandSecondaryColor;
$hybridProductsWrapPadding: (small: 3rem 0 5rem 0);
$hybridProductsSectionTitleColor: $brandWhite;
$hybridProductsSectionTitleH2Margin: 0 0 .5rem;
$hybridProductsSectionTitleH2FontSize: 2.4rem;
$hybridProductsSectionTitleH2LineHeight: 3.2rem;
$hybridProductsSectionTitleLinkFontSize: 1.4rem;
$hybridProductsSectionTitleLinkLineHeight: 2.6rem;
$hybridProductsSectionTitleLinkAfterDisplay: none;
$productItemWrapBoxShadow: inset 0 0 0 2px $brandWhite, inset 0 0 0 4px $brandGray;
$productItemProductInfoNameFont: normal 1.4rem/2rem $brandFontFamily;
$productItemProductInfoPriceFont: bold 1.6rem/2rem $brandFontFamily;
$productItemProductInfoRebateFont: 500 1.6rem/2rem $brandFontFamily;
$productItemProductInfoRebateColor: $brandPrimaryBlue;
$productItemMerchantBackgroundColor: #f7f7f7;
$productItemMerchantPFont: normal 1.4rem/2.4rem $brandFontFamily;
$productItemFlagFont: 600 1.2rem/1.7rem $brandFontFamily;
$productItemExtraFlagColor: $brandWhite;
$productItemExtraFlagBackgroundColor: $brandSecondaryColor2;
$productItemFlagBackgroundColor: #00b2a9;
$productItemFlagAfterBorderColor: transparent $brandPrimaryBlue;

/* Product Details Modal */
$productDetailsModalNameColor: $brandPrimaryBlue;
$productDetailsModalRebatePrefixColor: $brandPrimaryBlue;
$productDetailsModalSalePillBackground: #00b2a9;
$productDetailsModalPillOfferInfoMargin: 0 0 0 3rem;

/* Hybrid Stores */
$hybridStoresBackground: $brandWhite;
$hybridStoresViewAllLinkColor: $brandPrimaryBlue;
$hybridStoreMerchantTileBorder: 0;
$hybridStoreMerchantTilePositionTop: 0;
$hybridStoreMerchantTilePositionBottom: 0;
$hybridStoreMerchantTilePositionLeft: 0;
$hybridStoreMerchantTilePositionRight: 0;
$hybridStoreMerchantTileNameColor: $brandBlack;
$hybridStoreMerchantTileInstoreMerchantRebateColor: $brandPrimaryBlue;
$hybridStoresButtonContainerMargin: (small: 1rem 0 0, medium: .4rem 0 0);
$hybridStoresButtonContainerPadding: (small: 0 0 6rem, medium: 0 0 9rem);
$hybridStoresButtonBorder: 2px solid $brandPrimaryColor;
$hybridStoresButtonPadding: 1.2rem 3rem;
// Hybrid Stores Offer Overlay
$hybridStoresOfferOverlayHeadOfferSeparatorBorderTop: (small: 1px solid #ccc);
$hybridStoresOfferOverlayInstoreMerchantRebateColor: $brandPrimaryBlue;

/* Click History My Account Page */
$clickHistoryTooltipBeforeBorder: 1px solid $brandSecondaryColor2;
$clickHistoryTooltipBeforeBackground: $brandSecondaryColor2;

/* Gift Guide */
$giftGuideBackground: (small: rgba(182, 184, 220, .25), medium: unset);
$giftGuideInnerPadding: (small: 0);
$giftGuideButtonPadding: 1rem;
$giftGuideItemRebateWrapBackground: #f1f2f2;
$giftGuideItemMerchantWrapBorder: 1px solid #e7ecef;
$giftGuideItemMerchantLogoAfterBorder: 1px solid #e7ecef;
$giftGuideItemOfferDetailsColor: $brandBlack;
$giftGuideItemRebatePrefixColor: $brandPrimaryBlue;
$giftGuideItemTieredPrefixDisplay: none;
$giftGuideItemPromoCodeWrapColor: $brandBlack;
$giftGuideItemPromoCodeFontSize: 1.3rem;
$giftGuideItemPromoCodeFontWeight: 600;
$giftGuideItemPromoCodeLineHeight: 1.9rem;
$giftGuideItemPromoCodeMargin: 0 0 .75rem 0;
$giftGuideItemExpirationDateColor: $brandNeutralDarkColor3;
$giftGuideItemExpirationDateFontSize: 1rem;
$giftGuideItemExpirationDateLineHeight: 2rem;

/* Slide Menu */
$slideMenuTriggerFontWeight: 500;

/* Coupon Search Results */
$merchantWithCouponsRebatePrefixColor: $brandPrimaryBlue;
$merchantWithCouponsRebatePrefixFontWeight: 500;
$merchantWithCouponsRebateElevationOldValueAfterContent: ' ';
$scrFilterMobileTitleAfterFontFamily: 'UnitedIcons';
$scrFilterMobileTitleAfterContent: '\e909';
$scrFilterMobileTitleExpandedAfterContent: '\e90b';
$scrFilterMobileTitleAfterFontSize: 1.4rem;
$scrFilterMobileDropdownListItemInputAccentColor: #1a708c;
$scrFilterMobileMoreOrLessButtonColor: $brandPrimaryBlue;
$scrFilterMobileClearFilterButtonColor: $brandPrimaryBlue;
$scrFilterDesktopButtonAfterContent: '\e909';
$scrFilterDesktopButtonAfterFontFamily: 'UnitedIcons';
$scrFilterDesktopButtonAfterFontSize: 1.3rem;
$scrFilterDesktopDropdownListItemInputAccentColor: #1a708c;
$scrFilterDesktopFilteredMerchantColor: $brandPrimaryBlue;
$scrFilterDesktopFilteredMerchantButtonColor: $brandPrimaryBlue;
$scrFilterDesktopClearFiltersColor: $brandPrimaryBlue;
$hybridCouponTileRebatePrefixColor: $brandPrimaryBlue;
$hybridCouponTileRebatePrefixFontWeight: 500;
$hybridCouponTileRebateElevationOldValueAfterContent: ' ';

/* Product search results */
$psrMenuMobileClearFilterButtonColor: $brandPrimaryBlue;
$psrMenuMobileFilterMoreOrLessButtonColor: $brandPrimaryBlue;
$psrMenuMobileFilterDropdownListItemInputAccentColor: #1a708c;
$psrMenuDesktopActiveFilterColor: $brandPrimaryBlue;
$psrMenuDesktopActiveFiltersClearFiltersColor: $brandPrimaryBlue;
$psrMenuDesktopFiltersDropdownListItemInputAccentColor: #1a708c;
$psrMenuDesktopSortSelectAppearance: none;
$psrMenuDesktopSortSelectBackground: #fff url('~org/assets/images/chevron_down_1.svg') no-repeat 95% 55%;

/* Header Holiday Image */
$holidayImageMargin: (small: -2.3rem 0 0 0, medium: -3rem 0 0 0);

/* Holiday Border */
$holidayWrapBackground: $brandSecondaryColor2;

/* Favorites Landing */
// Favorited Merchants
$favoritedLandingMerchantsInfoIconColor: #69b3e7;
$favoritedLandingMerchantsInfoLinkTextDecoration: underline;
$favoritedLandingMerchantsInfoLinkHoverTextDecoration: none;
$favoritedLandingMerchantOffersCountAfterContent: '\e90a';
$favoritedLandingMerchantOffersCountAfterFontFamily: 'UnitedIcons';
$favoritedLandingMerchantOffersCountAfterFontSize: 1rem;
$favoritedLandingMerchantRebateInfoBorder: 2px solid $brandPrimaryColor;
$favoritedLandingMerchantRebateColor: $brandPrimaryColor;
$favoritedLandingMerchantRebateFontWeight: 500;
$favoritedLandingMerchantRebateValueColor: $brandPrimaryColor;
$favoritesLandingHIWItemFirstIconBackgroundImage: url('~org/modules/FavoritesHiw/assets/favorite_heart.svg');
$favoritesLandingHIWItemSecondIconBackgroundImage: url('~org/modules/FavoritesHiw/assets/favorites_cart.svg');
$favoritesLandingHIWItemThirdIconBackgroundImage: url('~org/modules/FavoritesHiw/assets/notifications.svg');
// Adoption
$favoritesAdoptionBackground: $brandWhite;
$favoritesAdoptionH3FontWeight: 500;
$favoritesAdoptionButtonsViewFavoritesBackground: $brandWhite;
$favoritesAdoptionButtonsViewFavoritesColor: $brandPrimaryColor;
$favoritesAdoptionButtonsViewFavoritesBorder: 2px solid $brandPrimaryColor;
$favoritesAdoptionButtonsViewFavoriteHoverBackground: #f2f2f2;
$favoritesAdoptionButtonsViewFavoriteHoverColor: $brandPrimaryColor;
$favoritesAdoptionButtonsViewFavoriteHoverBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .5);
$favoritesAdoptionButtonsViewFavoritesDisabledBorder: 2px solid $brandNeutralMediumColor;
$favoritesAdoptionButtonsViewFavoritesDisabledBackground: $brandNeutralMediumColor;
$favoritesAdoptionButtonsViewFavoritesDisabledColor: $brandWhite;
// HIW
$favoritesLandingHiwBackground: linear-gradient(180deg, #0d2340 0%, #0033a0 100%);
$favoritesLandingHiwColor: $brandWhite;
$favoritesLandingHiwH2Color: (small: $brandWhite, medium: ());
$favoritesLandingHiwCloseIconColor: $brandWhite;
$favoritesLandingHiwCloseIconWidth: 5.5rem;
$favoritesLandingHiwCloseIconHeight: 4rem;
$favoritesLandingHiwCloseIconBorder: 1px solid rgba(212, 212, 208, .5);
$favoritesLandingHiwCloseIconBorderWidth: 1px 1px 0 1px ;
$favoritesLandingHiwCloseIconBorderRadius: 4px 4px 0 0;
$favoritesLandingHiwCloseIconAfterContent: '\e90c';
$favoritesLandingHiwCloseIconAfterFontFamily: 'UnitedIcons';
$favoritesLandingHiwButtonBackground: $brandWhite;
$favoritesLandingHiwButtonBorder: 2px solid $brandPrimaryColor;
$favoritesLandingHiwButtonColor: $brandPrimaryColor;
$favoritesLandingHiwButtonHoverColor: $brandPrimaryColor;
$favoritesLandingHiwButtonHoverBorder: 2px solid $brandPrimaryColor;
$favoritesLandingHiwButtonHoverBackground: #f2f2f2;
$favoritesLandingHiwButtonHoverBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .5);
$favoritesLandingHiwItemTitleColor: $brandWhite;
$favoritesLandingHiwItemTitleFontWeight: 500;
$favoritesLandingHiwItemTextColor: $brandWhite;
$favoritesLandingHIWItemSecondBeforeAfterBackground: (
  small: linear-gradient(90deg, rgba(212, 212, 208, 0) 0%, rgba(212, 212, 208, .5) 17.53%, rgba(212, 212, 208, .5) 52.37%, rgba(212, 212, 208, .5) 80.86%, rgba(212, 212, 208, 0) 99.97%),
  medium: linear-gradient(0deg, rgba(212, 212, 208, 0) 0%, rgba(212, 212, 208, .5) 17.53%, rgba(212, 212, 208, .5) 52.37%, rgba(212, 212, 208, .5) 80.86%, rgba(212, 212, 208, 0) 99.97%)
);
// Manager

// Recommendations
$favoritesRecommendationsBackground: #ededf6;
// Favorites Search
$favoritesSearchConfirmationIconColor: #69b3e7;

/* Store Bundles */
$storeBundleBannerH3Color: $brandWhite;
$storeBundleBannerH3FontWeight: 500;
$storeBundleBannerH3Background: $brandSecondaryColor;
$storeBundleMerchantFavoriteIconFill: #69b3e7;
$storeBundleFavoriteAllButtonColor: $brandPrimaryColor;
$storeBundleFavoriteAllButtonBackground: $brandWhite;
$storeBundleFavoriteAllButtonFontSize: 1.6rem;
$storeBundleFavoriteAllButtonLineHeight: 2.5rem;
$storeBundleFavoriteAllButtonBorder: 2px solid $brandPrimaryColor;
$storeBundleFavoriteAllButtonBorderRadius: 4px;
$storeBundleFavoriteAllButtonFontWeight: 500;
$storeBundleFavoriteAllButtonHoverBackground: #f2f2f2;
$storeBundleFavoriteAllButtonHoverColor: $brandPrimaryColor;
$storeBundleFavoriteAllButtonHoverBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .5);
$storeBundleFavoriteAllSuccessBackground: $brandSecondaryColor;
$storeBundleFavoriteAllSuccessMessageColor: $brandWhite;

/* Dashboard Inquiry Tooltip */
$dashboardInquiryTooltipFormButtonColor: $brandViolet;
$dashboardInquiryTooltipFormButtonBeforeBorder: 1px solid $brandViolet;
$dashboardInquiryTooltipFormHintMinWidth: 20.6rem;
$dashboardInquiryTooltipFormHintMinHeight: 7rem;
$dashboardInquiryTooltipFormHintPadding: .8rem;
$dashboardInquiryTooltipFormHintBorder: 1px solid $brandViolet;
$dashboardInquiryTooltipFormHintBorderRadius: .4rem;

/* Profile */
$profileContentBeforeBackground: url('~org/assets/images/palma_bg2.jpg');
$profileAccountSectionH3FontWeight: 500;
$profileAccountSectionEditButtonFontWeight: (small: bold, medium: 500);
$profileFavoritesAlertSwitcherBackground: $brandNeutralMediumColor;
$profileFavoritesAlertSwitcherCheckedBackground: $brandPrimaryColor;
// Profile Tooltip
$profileTooltipFormButtonMargin: 0 0 0 .6rem;
$profileTooltipFormHintColor: $brandBlack;
$profileTooltipFormHintMinWidth: 24rem;
$profileTooltipFormHintPadding: .8rem;
$profileTooltipFormHintBorder: 1px solid $brandViolet;
$profileTooltipFormHintBorderRadius: .4rem;
// Account Snapshot
$accountSnapshotMobileBackgroundColor: rgb(44, 41, 82);
$accountSnapshotMobileBackgroundImage: url('~org/assets/images/palma_bg2.jpg');
$accountSnapshotMobileH3FontWeight: 500;
$accountSnapshotMobileButtonFontWeight: 400;
$accountSnapshotMobileEarningsWrapValueFontWeight: 400;
$accountSnapshotMobileTooltipWrapperHintBorder: 1px solid $brandViolet;
$accountSnapshotDesktopEarningsWrapValueFontWeight: 700;
$accountSnapshotDesktopTooltipWrapperButtonColor: #6244bb;
$accountSnapshotDesktopTooltipWrapperButtonBeforeBorder: 1px solid #6244bb;
// RecentActivity
$recentActivityTabsTitleButtonBackground: (small: #fff, medium: $brandSecondaryColor);
$recentActivityTabsTitleButtonBorderTop: (small: none, medium: solid .5rem $brandSecondaryColor);
$recentActivityTabsTitleButtonActiveBorderBottom: (small: solid .5rem $brandSecondaryColor, medium: 1px solid #d0dae0);
$recentActivityTabDisclaimerShowMoreColor: $brandPrimaryBlue;
$recentActivityTooltipWrapperButtonBeforeBackground: #024;
$recentActivityDesktopTableBodyTrTdPostDateWrapDayFontWeight: 600;
$recentActivityDesktopTableBodyTrTdComissionFontWeight: 500;
$recentActivityDesktopTableBodyTrTdRewardAmountFontWeight: 500;
// Instore
$profileInstoreAddNewCardButtonColor: $brandPrimaryBlue;
// Transaction Ad Unit
$transactionAdUnitRebateColor: $brandPrimaryBlue;

/* Safari Button Install Tutorial */
$safariButtonInstallPermissionsTutorialBackground: rgba(182, 184, 220, .25);
$safariButtonInstallPermissionsTutorialImageBackgroundImage: url('~org/assets/safariButtonInstallTutorial/permissionSetting/gettingStarted.png');
$nonSafariTutorialCopyLinkInputBorder: 1px solid #626b79;
$nonSafariTutorialCopyLinkInputColor: #626b79;
$nonSafariTutorialCopyLinkButtonBackground: $brandPrimaryColor;
$nonSafariTutorialCopyLinkButtonColor: $brandWhite;
$nonSafariTutorialCopyLinkedTextColor: $brandPrimaryColor;
$installTutorialStepsBackgroundColor: $brandWhite;
$installTutorialListStepsSelectedStepBackground: rgba(182, 184, 220, .25);
$installTutorialWatchAgainButtonBorder: 2px solid $brandPrimaryColor;
$installTutorialWatchAgainButtonBackgroundColor: $brandWhite;
$installTutorialWatchAgainButtonColor: $brandPrimaryColor;
$installTutorialVideosBackground: rgba(182, 184, 220, .25);
$installTutorialVideosExitButtonBackground: $brandSecondaryColor2;
$installTutorialVideosExitButtonColor: $brandWhite;
$installTutorialVideosExitButtonAfterFontFamily: $brandFontFamilyLight;

// Email Unsubscribe
$emailUnsubscribeLinkTextDecoration: underline;
$emailUnsubscribeLinkHoverTextDecoration: none;
$emailUnsubscribeAlertBeforeFontFamily: 'UnitedIcons';
$emailUnsubscribeAlertWarningBeforeContent: '\e910';
$emailUnsubscribeAlertSuccessBeforeContent: '\e904';

// Terms and conditions Modal
$termsAndConditionsModalH2FontWeight: 600;
$termsAndConditionsModalH2FontSize: 3.2rem;
$termsAndConditionsModalH2LineHeight: 4rem;
$termsAndConditionsModalH2Margin: 3rem 0 2rem;
$termsAndConditionsModalTextFontSize: 1.6rem;
$termsAndConditionsModalTextLineHeight: 2.4rem;

// Top Earn
$topEarnBackgroundImageUrl: url('~org/assets/images/palma_bg.jpg');

// Home Page
$homePageMhpTopWrapBackground: (small: $brandSecondaryColor2);
$homePageMhpBottomWrapBackground: (small: (), medium: #ecedf6);
