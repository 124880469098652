@import '~org/styles/variables';

#mn_acq {
  @media #{$medium-screen} {
    .mn_brandLogo {
      width: 20rem;
      height: 4.3rem;
      margin: 0 auto 0 0;
    }

    .mn_secondaryNavigation {
      display: flex;
      font-size: 1.1rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: .3rem;
      white-space: nowrap;
      flex-wrap: nowrap;

      .mn_itemWrap {
        margin: 0 0 0 2rem;

        a {
          color: #fff;
          letter-spacing: 1.1px;
          text-decoration: none;
        }
      }
    }

    .mn_userSection {
      padding: 0;
      margin: 0 0 0 3rem;
      border: none;

      .mn_userInfo {
        text-transform: none;

        .mn_userNameWrap {
          font-size: 1.6rem;
        }
      }

      .mn_lifetimeEarning {
        margin: 0;
        font-size: 1.4rem;
        letter-spacing: 0;
        font-weight: 400;
        color: #69b3e7;
        min-width: 17rem;
      }
    }

    .mn_accountNavigation {
      width: initial;
      align-items: center;

      .mn_navigationDropdownTrigger .mn_navigationDropdown {
        text-align: right;
        right: -7rem;

        .mn_highlitedSection {
          padding: 1.2rem 1.7rem 0;

          .mn_userId {
            text-transform: none;
            margin: 0 0 1rem;
          }

          .mn_lifetimeEarning {
            display: none;
          }
        }

        ul {
          margin: 0 0 1rem;

          a {
            padding: .3rem 0;
          }
        }
      }

      .mn_button {
        margin: 2.5rem 0 0 3rem;
        border: none;
        line-height: 1;
        height: auto;
        padding: 1.2rem 0;
        font-size: 1.5rem;
        letter-spacing: .8px;
        width: 14rem;
        text-transform: none;
        font-weight: 400;
      }
    }

    .mn_contentWrap {
      justify-content: flex-end;
    }

    .mn_acqNavWrap {
      padding: 3.5rem 0 0;
    }

    .mn_searchAndNavWrap,
    .mn_headerExtraRewardsSection,
    .mn_favoritesSection,
    .mn_accountNavigation .mn_navigationWrap,
    .mn_helpSection,
    .mn_instoreSection,
    .mn_sidebarTrigger {
      display: none;
    }
  }

  @media #{$large-screen} {
    .mn_secondaryNavigation {
      font-size: 1.2rem;

      .mn_itemWrap {
        a {
          letter-spacing: 1.7px;
        }
      }
    }

    .mn_acqNavWrap {
      padding: 2.5rem 0 0;
    }
  }
}
