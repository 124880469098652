@import '~org/styles/variables';

$interstitialEarningCategoryNameFontSize: 1.2rem !default;
$interstitialEarningColor: () !default;
$interstitialEarningCategoryRebateFontSize: 1.2rem !default;
$interstitialEarningCategoryElevationNewValueColor: $brandPrimaryBlue !default;
$interstitialEarningHeaderTextTransform: () !default;

#mn_merchInterstitial {
  position: relative;
  background: #fff;
}

#mn_initParams {
  display: none;
}

#mn_istitHead {
  height: 12rem;
  border-top: 6px solid #0033a0;
  padding: 3.3rem 0;
  background: #0c2340;
  box-shadow: inset 5px 120px 48px -55px rgba(0, 0, 0, .8);
  text-align: center;
}

.mn_institBrandLogo {
  display: inline-block;
  background: url('../assets/images/logo.svg') no-repeat;
  width: 22rem;
  height: 4.1rem;
  background-size: contain;

  @media #{$medium-screen} {
    width: 24rem;
    height: 5.3rem;
  }
}

#mn_istitCancel {
  float: left;
}

#mn_istitContinue {
  float: right;
}

#mn_istitBody {
  max-width: 720px;
  margin: 0 auto;
  padding: 6rem 2rem 0;
  font-weight: 400;

  .mn_logoWrap {
    width: 300px;
    margin: 0 auto 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mn_divider {
      width: 2px;
      border-right: 1px solid #0b203c;
      height: 4rem;
    }

    .mn_istitDirectMerchantLogo {
      height: 44px;
      width: 149px;
      color: #0033a0;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 2.2rem;
      text-align: center;
    }
  }

  .mn_istitMessage {
    font-size: 1.6rem;
    font-weight: 500;

    h1 {
      word-wrap: break-word;
      font-weight: 500;
      font-size: 3.2rem;
      letter-spacing: normal;
      text-align: center;
      color: #000;
    }

    .mn_istitTansMsg,
    .mn_simpleText,
    .mn_istitThanks {
      margin: 0;
    }

    .mn_simpleText {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 32px;
      margin: 35px 0 0 0;
    }

    .mn_istitThanks {
      margin: 25px 0 0 0;
    }

    .mn_istitTansMsg {
      text-align: center;
      border-bottom: 1px solid #ccc;
      padding-bottom: 2rem;

      .mn_istitRebateValue {
        display: block;
        margin: 2rem auto 0;
        padding: 0 0 7.5rem 0;
        font-weight: 500;
        font-size: 3.2rem;
        color: #006080;
      }
    }
  }

  .mn_buttonCtn {
    margin: 2rem auto;
    overflow: hidden;
    text-align: center;

    a {
      @extend .mn_button;

      margin: 1rem;
      padding: 1.5rem 1.9rem;

      @media #{$small-screen} {
        padding: 1.5rem 3.5rem;
      }

      @media #{$medium-screen} {
        width: 20rem;
      }
    }
  }

  .mn_istitTypeContinue & {
    #mn_istitCancel,
    #mn_istitContinue {
      float: none;
    }
  }

  #mn_istitDisclaim {
    padding: 20px 0 35px;
    font-size: 1.2rem;
    line-height: 2.4rem;
    font-weight: normal;
  }

  #mn_istitEarningCategories {
    margin: 5rem 0 3rem;

    h3 {
      font-weight: 500;
      font-size: 1.6rem;
      text-transform: $interstitialEarningHeaderTextTransform;
    }

    ul {
      text-align: left;
      margin-top: 15px;
      padding: 0;
      list-style-type: none;
      column-count: 2;
      column-gap: 31px;

      @media (max-width: 550px) {
        column-count: 1;
      }

      li {
        margin: 0;
        overflow: hidden;

        &:before {
          content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ';
          float: left;
          width: 0;
          white-space: nowrap;
          letter-spacing: -1px;
        }

        .mn_istitCategoryName,
        .mn_istitCategoryRebate {
          background-color: #fff;
        }

        .mn_istitCategoryName {
          font-size: $interstitialEarningCategoryNameFontSize;
          color: $interstitialEarningColor;
          padding-right: .3rem;
        }

        .mn_istitCategoryRebate {
          font-size: $interstitialEarningCategoryRebateFontSize;
          float: right;
          padding-left: .3rem;
          font-weight: bold;
          color: $interstitialEarningCategoryElevationNewValueColor;
        }
      }
    }
  }
}

#mn_statusBar {
  display: none;
}

.mn_istitTypeTimed #mn_statusBar {
  margin: 30px auto;
  background: url('../assets/images/loadingIndicator1.gif') no-repeat 50% 50%;
  font-size: 0;
  width: 50px;
  height: 20px;
  background-size: contain;
  display: block;
}

/* Type Continue */
.mn_istitTypeContinue {
  .mn_institBrandLogo {
    width: 22rem;
    height: 4.1rem;
    margin: 0 auto;
  }

  #mn_istitHead {
    height: 12rem;
    padding: 3.3rem 0;
  }

  /* stylelint-disable no-duplicate-selectors */
  #mn_istitBody {
    display: flex;
    flex-flow: column;
  }

  .mn_title {
    order: 1;
    border: 1px solid #ccc;
    padding: 20px 20px 20px 60px;
    position: relative;
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 2.4rem;

    &:before {
      content: '\e910';
      position: absolute;
      font-family: 'UnitedIcons', serif;
      color: #d50032;
      font-size: 22px;
      top: 18px;
      left: 20px;
    }

    &:after {
      content: '';
      background: #d50032;
      top: 0;
      bottom: 0;
      width: 8px;
      left: 0;
      position: absolute;
      border-radius: 4px 0 0 4px;
    }
  }

  .mn_istitMessage {
    order: 2;
    line-height: 2.4rem;
    padding: 2rem 0 1rem;
  }

  #mn_istitBody .mn_buttonCtn {
    display: flex;
    flex-flow: column;
    order: 4;
    margin: 0;

    @media #{$medium-screen} {
      align-items: center;
    }

    #mn_istitCancel {
      order: 2;
      background: none;
      border: none;
      color: #006080;
      text-decoration: underline;
      margin: 10px 0;

      &:hover,
      &:focus {
        background: none;
        border: none;
        color: #006080;
        box-shadow: none;
        text-decoration: none;
      }
    }

    #mn_istitContinue {
      order: 1;
      margin: 10px 0;
    }
  }

  .mn_disclaimer {
    order: 3;
    border-top: 1px solid #ccc;
    margin: 20px 0 0 0;
    padding: 20px 0 10px 0;
    font-size: 2rem;
    line-height: 3.2rem;
    font-weight: 500;

    span {
      display: none;
    }
  }
}

/* Type ITP */
.mn_istitTypeITP {
  h2 {
    order: 1;
    border: 1px solid #ccc;
    padding: 20px 20px 20px 60px;
    position: relative;
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 2.4rem;

    &:before {
      content: '\e910';
      position: absolute;
      font-family: 'UnitedIcons', serif;
      color: #d50032;
      font-size: 22px;
      top: 18px;
      left: 20px;
    }

    &:after {
      content: '';
      background: #d50032;
      top: 0;
      bottom: 0;
      width: 8px;
      left: 0;
      position: absolute;
      border-radius: 4px 0 0 4px;
    }
  }

  p {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.4rem;
  }

  hr {
    border-top: 1px solid #ccc;
    margin: 30px 0;
  }

  .mn_ITPExplanation {
    margin-top: 30px;
  }

  #mn_istitBody .mn_ITPButtonCtn {
    display: flex;
    flex-flow: column;
    margin: 0;

    a {
      @extend .mn_button;
    }

    @media #{$medium-screen} {
      align-items: center;
    }

    #mn_istitCancel {
      order: 2;
      background: none;
      border: none;
      color: #006080;
      text-decoration: underline;
      margin: 10px 0;

      &:hover,
      &:focus {
        background: none;
        border: none;
        color: #006080;
        text-decoration: none;
      }
    }

    #mn_istitContinue {
      order: 1;
      margin: 10px 0;
    }

    #mn_istitDisclaim {
      padding: 10px 0;
    }
  }
}

// Holiday styling
.mn_holiday {
  #mn_istitHead {
    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 3.5rem;
      width: 15rem;
      top: .2rem;
    }

    &:before {
      left: .2rem;
      background: url('../assets/images/holidayImage_Left.svg') no-repeat center center;
      background-size: contain;
    }

    &:after {
      right: .2rem;
      background: url('../assets/images/holidayImage_Right.svg') no-repeat center center;
      background-size: contain;
    }
  }

  .mn_istitTypeTimed {
    .mn_institBrandLogo {
      margin: 2rem auto 0;
    }

    #mn_statusBar {
      background: url('../assets/images/loadingIndicatorHoliday.gif') no-repeat 50% 0;
      background-size: 20rem;
      height: 8rem;
      padding: 0;
      margin: 2.4rem 0;
      width: 100%;
    }
  }

  @media #{$medium-screen} {
    #mn_istitHead {
      &:before,
      &:after {
        height: 6.6rem;
        width: 28.8rem;
      }
    }

    .mn_istitTypeTimed {
      .mn_institBrandLogo {
        margin: 0 auto;
      }
    }
  }
}
