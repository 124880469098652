@import '~org/styles/variables';

.mn_pageHeader {
  position: relative;
  z-index: 3;

  .mn_searchBar {
    position: relative;
    z-index: 1;
    margin-bottom: 5rem;
  }

  .mn_headerInner {
    background: #0b2340;
    transform: translateZ(0);
    transition: opacity .5s ease-in-out;
  }

  .mn_headerMainBar {
    position: relative;
    z-index: 3;
    border-top: 4px solid #0033a0;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      height: 1px;
      width: 100%;
      background: linear-gradient(90deg, rgba(212, 212, 208, 0) 0%, rgba(212, 212, 208, .5) 17.53%, rgba(212, 212, 208, .5) 52.37%, rgba(212, 212, 208, .5) 80.86%, rgba(212, 212, 208, 0) 99.97%);
    }
  }

  // Sticky
  &.mn_sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    transform: translateZ(0);

    .mn_headerInner {
      opacity: 1;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .4);
    }

    .mn_headerMainBar {
      transition: .2s padding ease-in-out, .2s background ease-in-out;
      background: #0b2340 linear-gradient(180deg, #000 0%, #0b2340 50px, #0b2340 80px, #0b2340 100%);

      .mn_brandLogo a {
        &:before,
        &:after {
          transition: all .5s ease-in-out;
        }
      }
    }
  }

  .mn_brandLinks {
    display: none;
    text-align: center;
    margin: 0 3.5rem;

    &:after {
      content: '';
      background: linear-gradient(90deg, rgba(212, 212, 208, 0) 0%, rgba(212, 212, 208, .5) 17.53%, rgba(212, 212, 208, .5) 52.37%, rgba(212, 212, 208, .5) 80.86%, rgba(212, 212, 208, 0) 99.97%);
      position: absolute;
      height: .7px;
      bottom: 0;
      left: -2rem;
      right: -2rem;
    }

    .mn_button {
      min-width: 16.6rem;
      margin: 0 auto 1rem;
      display: inline-block;
      padding: .9rem 3rem;
    }
  }

  @media #{$medium-screen} {
    .mn_headerMainBar {
      border-top: 8px solid #0033a0;
    }

    .mn_searchBar {
      margin-bottom: unset;
    }
  }
}

.mn_pageHeaderOffset {
  overflow: hidden;
  visibility: hidden;
  flex: 0 0 0;
}

// VHP header
html:not(.mn_open) #mn_h {
  .mn_pageHeader.mn_isAnonymous {
    .mn_brandLinks {
      display: block;
    }

    .mn_headerMainBar {
      @media #{$xlarge-screen} {
        .mn_contentWrap {
          justify-content: flex-start;
        }
      }
    }

    .mn_accountNavigation,
    .mn_searchAndNavWrap {
      @media #{$medium-screen} {
        margin-left: auto;
      }
    }

    &:not(.mn_sticky) {
      .mn_headerMainBar {
        background: linear-gradient(180deg, #000 0, #04060b 50px, #121f35 100%);

        @media #{$medium-screen} {
          background: unset;
        }
      }

      .mn_headerInner {
        box-shadow: inset 6.5px 120px 48px -55px rgba(0, 0, 0, .8);
        background: linear-gradient(#000 4.5rem, rgba(40, 41, 62, .3) 12rem, rgba(125, 129, 195, .1) 91.92%, rgba(125, 129, 195, 0) 100%);

        @media #{$medium-screen} {
          background: linear-gradient(#000 4.5rem, rgba(40, 41, 62, .3) 6rem, rgba(125, 129, 195, .1) 91.92%, rgba(125, 129, 195, 0) 100%);
        }

        @media #{$xlarge-screen} {
          background: linear-gradient(#000 7.5rem, rgba(40, 41, 62, .3) 12rem, rgba(125, 129, 195, .1) 91.92%, rgba(125, 129, 195, 0) 100%);
        }
      }
    }
  }
}

.mn_VHP {
  &:not(.mn_holiday) {
    #mn_h .mn_pageHeader.mn_isAnonymous:not(.mn_sticky) .mn_headerInner {
      background: linear-gradient(#000 8rem, rgba(40, 41, 62, .3) 12rem, rgba(125, 129, 195, .1) 17rem, rgba(125, 129, 195, 0) 100%);

      @media #{$medium-screen} {
        background: linear-gradient(180deg, #000 0, rgba(40, 41, 62, .1) 20%, rgba(125, 129, 195, 0) 91.92%);
      }
    }
  }
}
