@import '~org/styles/variables';

$navBarBorderColor: rgba(0, 0, 0, .04) !default;
$navBarItemColor: #231f20 !default;

.mn_navBar {
  color: #fff;

  .mn_mainNav {
    display: flex;
    flex-direction: column;
    user-select: none;
    margin-bottom: 1rem;

    &.mn_contentSection {
      padding: 0;
    }
  }

  .mn_extraRewardsPill {
    width: 7.7rem;
    left: calc(100% + 1rem);
    transform: none;
  }
}
