@import '~org/styles/variables';

$termsAndConditionsModalH2Color: () !default;
$termsAndConditionsModalH2FontWeight: () !default;
$termsAndConditionsModalH2FontSize: () !default;
$termsAndConditionsModalH2LineHeight: () !default;
$termsAndConditionsModalH2Margin: () !default;

$termsAndConditionsModalTextColor: () !default;
$termsAndConditionsModalTextFontSize: () !default;
$termsAndConditionsModalTextLineHeight: () !default;

.mn_termsAndConditionsModal {
  .mn_sectionTitle {
    h2 {
      color: $termsAndConditionsModalH2Color;
      font-weight: $termsAndConditionsModalH2FontWeight;
      font-size: $termsAndConditionsModalH2FontSize;
      line-height: $termsAndConditionsModalH2LineHeight;
      margin: $termsAndConditionsModalH2Margin;
    }
  }

  .mn_termsAndConditionsPopupWrap {
    .mn_termsText {
      color: $termsAndConditionsModalTextColor;
      font-size: $termsAndConditionsModalTextFontSize;
      line-height: $termsAndConditionsModalTextLineHeight;
    }
  }
}
