@import '~org/styles/variables';

.mn_backToTopButtonSticky {
  position: relative;

  .mn_backToTopButton {
    position: absolute;
    bottom: 0;
    color: #6244bb;
    width: 4.5rem;
    height: 4.5rem;
    right: 1.5rem;
    border: 2px solid #6244bb;
    border-radius: 100%;
    background: #fff;
    margin: 0 auto;
    font: normal 1.7rem/1.4 $brandFontFamilyLight;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(2.3rem); // height / 2

    &.mn_isSticky {
      position: fixed;
      bottom: 15rem;
    }

    &.mn_hiddenClone {
      display: none;
    }

    i {
      margin-top: .1rem;
      font-size: 2rem;
    }

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
}
