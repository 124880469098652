@import '~core/styles/mixins';
@import 'variables';

/* Mixin for generating radial shadow */
@mixin radial-shadow($top, $bottom, $height: 20px, $opacity: .15, $verticalPosition: 0%) {
  position: relative;

  &:after {
    content: '';
    background: -webkit-radial-gradient(50% $verticalPosition, 50% $height, rgba(191, 191, 199, $opacity) 0%, rgba(255, 255, 255, 0) 100%);
    height: $height;
    width: 100%;
    left: 0;
    top: $top;
    bottom: $bottom;
    position: absolute;
    display: block;
  }
}

@mixin mn_linkWithArrow($top: .5rem) {
  &:after {
    content: '\e90a';
    font-family: 'UnitedIcons', serif;
    font-size: 1rem;
    line-height: 1;
    vertical-align: top;
    display: inline-block;
    margin: $top -1rem 0 .5rem;
  }
}

%mn_linkWithArrow {
  &:after {
    content: '\e90a';
    font-family: 'UnitedIcons', serif;
    font-size: 1rem;
    line-height: 1;
    vertical-align: top;
    display: inline-block;
    margin: .6rem -2rem 0 .5rem;
  }
}

%mn_lightButton {
  padding: 1rem 1rem;
  min-width: 110px;
  background: #fff;
  border: $brandPrimaryColor solid 2px;
  color: $brandPrimaryColor;
  line-height: 1.5;

  &:hover {
    background: #f2f2f2;
    color: $brandPrimaryColor;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .5);
  }

  @media #{$large-screen} {
    min-width: 140px;
  }
}

/*
  Mixin for generating part of body background, which is aligned with Extra Rewards pill
  Top OR bottom value must be passed
*/
@mixin extraRewardsPillAlignedBackground($top, $bottom) {
  position: relative;

  &:before {
    content: '';
    background: $brandSecondaryColor;
    left: -50%;
    right: -50%;
    height: 1000px;
    position: absolute;
    top: $top;
    bottom: $bottom;
    z-index: -1;
  }
}
