@import '~org/styles/variables';

.mn_brandFooter {
  background-color: #e9e9e7;
  color: $brandFooterText;
  font: normal 1.4rem/2rem $brandFontFamily;

  .mn_brandFooterInnerWrap {
    padding: 5rem 0;
  }

  &.mn_bottomStickyPadding {
    padding-bottom: 5.7rem;

    &.mn_isAnonymous {
      padding-bottom: 9.7rem;
    }
  }

  a {
    color: $brandFooterText;
  }

  .mn_footerDisclaimer {
    margin: 2.5rem 0 0;
    padding: 2rem 1.5rem;
    font-size: 1.2rem;
    border-top: 1px solid #d9d9d8;

    sup {
      line-height: 0;
    }
  }

  .mn_footerNavigation {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0 0 1.5rem;
        padding: 0;
      }
    }
  }
}
