@import '~org/styles/variables';

.mn_headerSecondaryBar {
  pointer-events: none;
  position: fixed;
  top: 100%;
  right: -400px;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 8rem - 3px); // (100% screen height) - (header height) - (border top)
  background: linear-gradient(180deg, #0c2340 0%, #0033a0 100%);
  transform: translate3d(0, 0, 0);
  padding: 0 2.5rem 10rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: all .5s;
  opacity: 0;

  .mn_open & {
    pointer-events: auto;
    transform: translate3d(-400px, 0, 0);
    opacity: 1;
  }

  @media #{$medium-screen} {
    width: 40rem;
    opacity: 1;

    .mn_open & {
      max-height: none;
    }
  }
}

/* Full page height navigation background */
@media #{$medium-screen} and #{$less-than-xlarge-screen} {
  body {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -40rem;
      z-index: 3;
      width: 40rem;
      background: linear-gradient(180deg, #000 0%, #0b2340 5rem, #0b2340 8rem, #0b2340 50rem, #01339e 52rem);
      transition: all .5s;
    }
  }

  html.mn_open {
    overflow-x: hidden;

    body {
      overflow: visible;

      &:before {
        transform: translateX(-40rem);
        visibility: visible;
        max-height: none;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .5);
      }
    }
  }
}
