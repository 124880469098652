@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_headerMainBar {
  padding: 2.3rem 0 2.3rem;
  background: #0b2340 linear-gradient(180deg, #000 0, #0b2340 50px, #0b2340 80px, #0b2340 100%);

  &:after {
    content: '';
    background: linear-gradient(90deg, rgba(212, 212, 208, 0) 0%, rgba(212, 212, 208, .5) 17.53%, rgba(212, 212, 208, .5) 52.37%, rgba(212, 212, 208, .5) 80.86%, rgba(212, 212, 208, 0) 99.97%);
    height: .5px;
    display: block;
    position: absolute;
    bottom: 1px;
    width: 100%;
  }

  .mn_contentWrap {
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  .mn_searchAndNavWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 57.5rem;
    margin-left: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mn_headerTopNavigation {
    display: flex;
    flex: 0 0 auto;
    padding: 0;
    margin: 0 2rem 0 10%;
    align-items: center;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      border-right: .75px solid rgba(212, 212, 208, .5);
      top: -.2rem;
      bottom: -.2rem;
      right: -1.5rem;
    }

    .mn_searchTrigger {
      outline: none;

      &[aria-expanded='true'] {
        &:before {
          content: '\e90c'; // close icon
        }
      }
    }
  }

  @media #{$medium-screen} {
    padding: 2.85rem 0;

    &:after {
      display: none;
    }
  }

  @media #{$xlarge-screen} {
    .mn_contentWrap {
      justify-content: space-between;
    }

    .mn_headerTopNavigation:after {
      display: none;
    }
  }
}
