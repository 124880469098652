@import '~org/styles/variables';

.mn_brandLogo {
  display: block;
  width: 190px;
  height: 40px;
  margin: 0 auto 0 0;

  a {
    display: block;
    height: 100%;
    overflow: hidden;
    text-indent: -200%;
    position: relative;
    font-size: 0;
    line-height: 0;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../../assets/logo.svg) no-repeat 0 0;
      background-size: 100%;
      opacity: 1;
    }
  }

  @media #{$medium-screen} {
    width: 27.5rem;
    height: 6rem;
  }

  @media #{$large-screen} {
    margin: 0 auto 0 0;
  }

  @media #{$xlarge-screen} {
    margin: 0;
  }
}
