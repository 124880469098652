@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_mainNavigationTOF {
  width: 27.5rem;
  margin-right: 2.5rem;

  /* Navigation drop down */
  .mn_navigationDropdownWrapper {
    position: relative;
    padding-bottom: 3rem;
    margin-bottom: -3rem;

    .mn_navigationDropdown {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transform: translate3d(0, -10px, 0);
      transition: all .3s ease-in-out;
      position: absolute;
      top: 5rem;
      left: -24rem;
      width: 108rem;
      color: #fff;
      padding: 1px 0 0;
      z-index: 2;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      background: linear-gradient(180deg, #0c2340 0%, #0033a0 100%);
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);

      &:after {
        content: '';
        height: .7px;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        background: radial-gradient(circle at center, rgba(212, 212, 208, .5) 0, rgba(212, 212, 208, .5) 100px, rgba(212, 212, 208, 0) 1000px);
      }
    }

    .mn_navigationDropdownTrigger {
      display: block;
      background: none;
      border: none;
      border-bottom: .75px solid rgba(212, 212, 208, .5);
      color: #fff;
      font-size: 1.2rem;
      line-height: 2.4rem;
      height: 2.4rem;
      padding: 0 1.5rem 0 .5rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      width: 100%;
      text-align: left;

      .mn_navArrow {
        position: absolute;
        top: 0;
        right: 0;

        &:before {
          font-family: 'UnitedIcons', serif;
          content: '\e909';
          font-size: 1.4rem;
          line-height: 2.4rem;
        }
      }
    }

    &.mn_focused {
      .mn_navigationDropdown {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        pointer-events: all;
        visibility: visible;
      }

      .mn_navigationDropdownTrigger {
        .mn_navArrow:before {
          content: '\e90b';
        }
      }
    }
  }

  /* Common navigation lists styles */
  h2 {
    font-size: 2rem;
    color: #fff;
    font-weight: 500;
    margin: 0 0 1.5rem;
  }

  .mn_navDropdownMenu {
    width: auto;
    flex: 0 0 auto;
    margin: 0 -1rem;
    padding: 0;
    border: none;
    list-style: none;

    li {
      margin: 0;

      & > a {
        display: block;
        padding: 1.2rem 1rem;
        color: #fff;
        text-shadow: 0 1px rgba(0, 0, 0, .2);
        letter-spacing: .29px;
        font-size: 1.4rem;
      }
    }
  }

  .mn_leftMainNavPanelSect {
    flex: 0 0 35.1rem;
    max-width: 35.1rem;
    padding: 2rem 1rem 5rem 4.5rem;
    border-right: rgba(212, 212, 208, .5) solid 1px;
  }

  .mn_middleMainNavPanelSect {
    flex: 0 0 36rem;
    padding: 2rem 4.5rem 0;
    max-width: 36.1rem;
    border-right: rgba(212, 212, 208, .5) solid 1px;
    margin-bottom: 26rem;
  }

  .mn_rightMainNavPanelSect {
    flex: 0 0 36rem;
    max-width: 36rem;
    padding: 2rem 4.5rem 0;
    margin-bottom: 26rem;
  }

  .mn_bottomMainNavPanelSect {
    width: 63rem;
    position: absolute;
    bottom: 10.5rem;
    left: 40rem;
  }
}
