@font-face {
  font-family: 'UnitedIcons';
  src: url('../fonts/UnitedIcons.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.mn_unitedIcon,
[class*='mn_unitedIcon'] {
  font-family: UnitedIcons, serif !important;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  line-height: 1;
}

.mn_unitedIconExternalLink:before {
  content: '\e917';
}

.mn_unitedIconSearch:before {
  content: '\e901';
}

.mn_unitedIconHelp:before {
  content: '\e916';
}

.mn_unitedIconRating:before {
  content: '\e915';
}

.mn_unitedIconPlus:before {
  content: '\e911';
}

.mn_unitedIconAlert:before {
  content: '\e910';
}

.mn_unitedIconArrowUp:before {
  content: '\e906';
}

.mn_unitedIconArrowBack:before {
  content: '\e90f';
}

.mn_unitedIconCreditCard:before {
  content: '\e90e';
}

.mn_unitedIconEdit:before {
  content: '\e90d';
}

.mn_unitedIconClose:before {
  content: '\e90c';
}

.mn_unitedIconChevronUp:before {
  content: '\e90b';
}

.mn_unitedIconChevronDown:before {
  content: '\e909';
}

.mn_unitedIconChevronLeft:before {
  content: '\e900';
}

.mn_unitedIconChevronRight:before {
  content: '\e90a';
}

.mn_unitedIconCheck:before {
  content: '\e908';
}

.mn_unitedIconCart:before {
  content: '\e907';
}

.mn_unitedIconInfo:before {
  content: '\e905';
}

.mn_unitedIconSuccess:before {
  content: '\e904';
}

.mn_unitedIconNotification:before {
  content: '\e903';
}

.mn_unitedIconLocation:before {
  content: '\e902';
}

.mn_unitedIconUser:before {
  content: '\e912';
}

.mn_unitedIconHamburgerMenu:before {
  content: '\e914';
}

.mn_unitedIconHeart:before {
  content: '\e913';
}

// SVG icons
.mn_SVGIconExtraMiles {
  background: url(../assets/images/icons/extra_miles.svg) no-repeat center/contain;
}

.mn_SVGIconExtraMilesPlus {
  background: url(../assets/images/icons/extra_miles_plus.svg) no-repeat center/contain;
}

.mn_SVGIconExtraMilesPlusBlack {
  background: url(../assets/images/icons/extra_miles_plus_black.svg) no-repeat center/contain;
  width: 2.2rem;
  height: 2.4rem;
  display: inline-block;
}
