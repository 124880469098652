@import '~core/styles/mixins';
@import '~org/styles/variables';

.mn_searchTriggerWrapper {
  position: relative;
  margin-right: 2rem;

  button {
    font-size: 2.2rem;
    color: #fff;
    padding: 0;
    background: none;
    border: none;
    display: block;
    height: 2.5rem;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    border-right: .75px solid rgba(212, 212, 208, .5);
    top: -.2rem;
    bottom: -.2rem;
    right: -1.5rem;
  }
}
