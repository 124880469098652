@import '~org/styles/variables';

.mn_mobileNavWelcomeMessage {
  text-align: center;
  padding: 2rem 1.5rem;
  color: #fff;
  position: relative;

  .mn_button {
    display: block;
    max-width: 22rem;
    text-transform: none;
    padding: 0;
    margin: 0 auto;
    height: 4.8rem;
    line-height: 4.8rem;

    &.mn_logoutLink {
      margin-top: 1rem;
    }
  }

  h3 {
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 400;
  }

  p {
    margin: 0;
  }
}
